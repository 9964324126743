import {Table, TableBody, TableHead, TableRow} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import LaunchIcon from '@material-ui/icons/Launch';
import React from 'react';
import FilePreview from '../../../modules/file/FilePreview';
import {HoopsTableCell} from '../HoopsTable';
import {WysiwygText} from '../../../componentsLib/Basic';

export const JobProofsTable = ({proofs = []}) => (
        <Table size='small'>
            <TableHead>
                <TableRow>
                    <HoopsTableCell>Preview</HoopsTableCell>
                    <HoopsTableCell>File Name</HoopsTableCell>
                    <HoopsTableCell>Linked Products</HoopsTableCell>
                    <HoopsTableCell />
                </TableRow>
            </TableHead>
        <TableBody>
            {proofs.map(
                (proof) => <TableRow key={proof._id}>
                <HoopsTableCell>
                    <FilePreview file={proof.file} />
                </HoopsTableCell>
                <HoopsTableCell>
                    {proof.file.filename}
                </HoopsTableCell>
                <HoopsTableCell>
                    {proof.products.map((product, idx) => <div key={idx}><WysiwygText text={product.description} /></div>)}
                </HoopsTableCell>
                <HoopsTableCell align='right'>
                    <Link href={proof.file.url} color={'primary'} underline={'none'}>
                        <LaunchIcon size='small' style={{fontSize: '18px'}} />
                    </Link>
                </HoopsTableCell>
            </TableRow>
            )}
        </TableBody>
      </Table>
    );

export default JobProofsTable;

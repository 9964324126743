import React, {createContext, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import {ArrowDropDown, ArrowDropUp} from '@mui/icons-material';
import {BusySpinner, TextInput} from '../Basic';
import {ModelessPopover, useModelessPopoverState} from './ModelessPopover';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes, scrollIntoView} from '../utils';
import {Row} from '../Layout';
import {CaptionText, HeadingText} from '../Text';

registerGlobalStyle('.autocomplete-popover', (theme) => ({
  '.search-bar': {
    position: 'sticky',
    top: 0,
    padding: theme.spacing(.75, 1, .5),
    borderBottom: `1px solid ${theme.colors.border.light}`,
    backgroundColor: theme.colors.background.almostWhite,
    columnGap: theme.spacing(1),
  },
  '.end-buttons': {
    position: 'sticky',
    bottom: 0,
    padding: theme.spacing(1.5, 1),
    borderTop: `1px solid ${theme.colors.border.light}`,
    backgroundColor: theme.colors.background.almostWhite,
    justifyContent: 'space-between',
    '&:has(>:only-child)': {justifyContent: 'center'},
  },
  '.autocomplete-list': {
    flexGrow: 1,
    width: '100%',
    listStyle: 'none',
    padding: theme.spacing(1, .25),
    margin: 0,
    overflowY: 'auto',
    '&:first-child::before, &:last-child::after': {
      display: 'block',
      content: '""',
      position: 'sticky',
      height: theme.spacing(1.25),
      left: 0,
      width: '100%',
      backgroundColor: 'transparent',
    },
    '&:first-child': {
      paddingTop: 0,
      '&::before': {
        top: 0,
        backgroundImage: 'linear-gradient(to top, #FFFFFF00, #FFFFFFFF)',
      },
    },
    '&:last-child': {
      paddingBottom: 0,
      '&::after': {
        bottom: 0,
        backgroundImage: 'linear-gradient(to bottom, #FFFFFF00, #FFFFFFFF)',
      }
    },
  },
  '.loading, .autocomplete-list.empty-list': {
    flexGrow: 1,
    alignSelf: 'center',
    textAlign: 'center',
    padding: theme.spacing(1, 2),
    color: theme.colors.text.medium,
  },
  '.autocomplete-heading': {
    padding: theme.spacing(0, .5),
    backgroundColor: theme.colors.background.almostWhite,
  },
  '&.search-value + .autocomplete-list': {overflowY: 'auto',},
  '.autocomplete-list li': {
    padding: theme.spacing(1),
    cursor: 'pointer',
    '&>.column': {
      alignSelf: 'center',
      gap: theme.spacing(.25),
    },
    '.title': {
      fontSize: '1rem',
      lineHeight: '1.25',
      whiteSpace: 'nowrap',
    },
    '.details': {
      fontSize: '.625rem',
      lineHeight: '1.25',
      whiteSpace: 'nowrap',
      '&>span': {color: theme.colors.text.medium},
    },
    '&:hover': {backgroundColor: theme.colors.background.hover,},
    '&.selected': {backgroundColor: theme.colors.background.selected,},
    '&.selected:hover': {backgroundColor: theme.colors.background.selectedHover,},
  },
  '.popover-elevation': {border: `1px solid ${theme.colors.border.light}`},
  '.scroll': {maxHeight: '400px',},
  '&:not(.no-min-size) .scroll:has(.end-buttons)': {
    minWidth: '440px',
    minHeight: 'max(50vh, 400px)',
    maxHeight: 'max(50vh, 400px)',
  },
}));

registerGlobalStyle('.autocomplete', (theme) => ({
  '.placeholder': {
    color: theme.colors.text.mediumDecorator,
    width: '100%',
  },
  '&.disabled, &.disabled *': {pointerEvents: 'none',},
  '.input-outline': {
    paddingRight: 0,
    ':has(>.placeholder)': {paddingBlock: theme.spacing(.875, .75)},
  },
  '.text-input': {paddingRight: 0,},
  'input': {textOverflow: 'ellipsis'},
  '.select-value': {
    display: 'flex',
    paddingRight: 0,
    lineHeight: 'normal',
    cursor: 'pointer',
    '&>div:first-child': {
      flex: '1 1 0',
      overflow: 'hidden',
      '*': {
        lineHeight: 'normal',
        textWrap: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
  },
  '.suffix': {
    color: 'transparent',
    cursor: 'pointer',
    transition: theme.transitions.out.color,
  },
  '&:hover .suffix, &:focus-within .suffix, &:focus .suffix': {
    color: theme.colors.text.mediumIcon,
    transition: theme.transitions.in.color,
  },
}));

const AutoCompleteContext = createContext(null);
export const useAutoCompleteContext = () => useContext(AutoCompleteContext);

export function AutoCompleteContainer(
  {
    className,
    allowAnyValue,
    contextRef,
    disabled,
    highlightedValue: initHighlightedValue,
    label,
    loading,
    search,
    placeholder,
    value,
    onChange,
    onClose,
    onListItemClick,
    onSearchChange,
    children
}) {
  const [valueAtFocus, setValueAtFocus] = useState(value);
  const [highlightedValue, setHighlightedValue] = useState(initHighlightedValue);
  const {open, anchorEl, anchorElRef, openPopover, closePopover, togglePopover} = useModelessPopoverState();
  const selectedElRef = useRef();
  const listElRef = useRef();

  useEffect(() => {
    setHighlightedValue(initHighlightedValue);
  }, [initHighlightedValue]);

  const handleFocus = useCallback(() => {
    setValueAtFocus(value);
    openPopover();
  }, [openPopover, value]);

  const handleBlur = useCallback((e) => {
    if (!e.relatedTarget?.closest('.autocomplete-popover')) {
      // If the focus was moved to an element outside the popover, close the popover
      closePopover();
    }
  }, [closePopover]);

  const handleClickAway = useCallback(() => {
    if (highlightedValue) {
      onChange(highlightedValue);
    } else if (allowAnyValue) {
      onChange(search);
    }
  }, [allowAnyValue, highlightedValue, onChange, search]);

  const moveHighlight = useCallback((direction) => {
    // Find the element that is currently highlighted or selected
    let el = listElRef.current?.querySelector(`li[value="${highlightedValue ?? value}"]`);
    if (!el) {
      // If there isn't one use the first or last child, depending on direction
      el = listElRef.current?.querySelector(direction === 'ArrowDown' ? 'li:last-child' : 'li:first-child');
    }
    if (el) {
      if (direction === 'ArrowDown') {
        el = el.nextElementSibling ? el.nextElementSibling : el.parentElement.firstElementChild;
      } else {
        el = el.previousElementSibling ? el.previousElementSibling : el.parentElement.lastElementChild;
      }
      const newHighlightedValue = el?.getAttribute('value');
      if (newHighlightedValue) {
        scrollIntoView(el);
        setHighlightedValue(newHighlightedValue);
      }
    }
  }, [highlightedValue, value]);

  const handleInputKeyDown = useCallback((e) => {
    if (!open) {
      openPopover();
    }

    switch (e.code) {
      case 'Enter':
      case 'Tab':
        if (highlightedValue) {
          onChange(highlightedValue);
        } else if (allowAnyValue) {
          onChange(search);
        }
        closePopover();
        break;

      case 'Escape':
        closePopover();
        onChange(valueAtFocus);
        e.stopPropagation();
        e.preventDefault();
        break;

      case 'ArrowDown':
      case 'ArrowUp':
        moveHighlight(e.code);
        e.stopPropagation();
        e.preventDefault();
        break;
    }
  }, [allowAnyValue, closePopover, highlightedValue, moveHighlight, onChange, open, openPopover, search, valueAtFocus]);

  const handleListItemClick = useCallback((e) => {
    onListItemClick?.(e);
    if (!e.defaultPrevented) {
      const newValue = e.target.closest('li')?.getAttribute('value');
      closePopover();
      if (newValue && newValue !== value) {
        onChange?.(newValue);
      }
      if (anchorElRef.current && anchorElRef.current.contains(document.activeElement)) {
        document.activeElement.blur();
      }
    }
  }, [anchorElRef, closePopover, onChange, onListItemClick, value]);

  const handlePopoverShownChange = useCallback((shown) => {
    if (shown) {
      if (anchorElRef.current) {
        const focusTo = anchorElRef.current.querySelector('input') ?? anchorElRef.current;
        focusTo.focus();
      }
      if (selectedElRef.current) {
        scrollIntoView(selectedElRef.current);
      }
    } else if (onClose) {
      onClose();
    }
  }, [anchorElRef, onClose]);

  useEffect(() => {
    if (initHighlightedValue && children && selectedElRef.current) {
      scrollIntoView(selectedElRef.current);
    }
  }, [children, initHighlightedValue]);

  const autoCompleteState = useMemo(() => ({
    currentValue: value,
    highlightedValue,
    placeholder,

    anchorEl,
    anchorElRef,
    listElRef,
    selectedElRef,

    search,
    onSearchChange,

    disabled,
    loading,

    isPopoverOpen: open,
    openPopover,
    closePopover,
    togglePopover,
    onPopoverShownChange: handlePopoverShownChange,
    onPopoverClickAway: handleClickAway,

    onInputFocus: handleFocus,
    onInputBlur: handleBlur,
    onInputKeyDown: handleInputKeyDown,

    onListItemClick: handleListItemClick,
  }), [
    value,
    highlightedValue,
    placeholder,
    anchorEl,
    anchorElRef,
    search,
    onSearchChange,
    disabled,
    loading,
    open,
    openPopover,
    closePopover,
    togglePopover,
    handlePopoverShownChange,
    handleClickAway,
    handleFocus,
    handleBlur,
    handleInputKeyDown,
    handleListItemClick,
  ]);

  if (contextRef) {
    contextRef.current = autoCompleteState;
  }

  return (
    <AutoCompleteContext.Provider value={autoCompleteState}>
      {label && <CaptionText>{label}</CaptionText>}
      <div className={classNames([className, 'autocomplete', disabled && 'disabled', !disabled && 'enabled'])} ref={anchorElRef}>
        {children}
      </div>
    </AutoCompleteContext.Provider>
  );
}

AutoCompleteContainer.propTypes = {
  className: HoopsPropTypes.className,
  allowAnyValue: HoopsPropTypes.bool,
  contextRef: HoopsPropTypes.object,
  disabled: HoopsPropTypes.bool,
  highlightedValue: HoopsPropTypes.string,
  label: HoopsPropTypes.string,
  placeholder: HoopsPropTypes.string,
  search: HoopsPropTypes.string,
  value: HoopsPropTypes.string,
  onChange: HoopsPropTypes.func,
  onClose: HoopsPropTypes.func,
  onListItemClick: HoopsPropTypes.func,
  onSearchChange: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};

export function AutoCompleteValueContainer({className, initialOpen, showOpenArrow, children}) {
  const {isPopoverOpen, togglePopover, onInputFocus, onInputBlur, onInputKeyDown, placeholder} = useAutoCompleteContext();
  const containerRef = useRef(null);

  useLayoutEffect(() => {
    if (initialOpen && containerRef.current) {
      containerRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {arrowDropDown, arrowDropUp} = useMemo(() => showOpenArrow ? {
    arrowDropDown: <ArrowDropDown onClick={togglePopover}/>,
    arrowDropUp: <ArrowDropUp onClick={togglePopover}/>
  } : {}, [showOpenArrow, togglePopover]);

  return (
    <div
      className={classNames([className, 'autocomplete-value-container', isPopoverOpen && 'popover-open'])}
      tabIndex={0}
      onMouseDown={togglePopover}
      onFocus={onInputFocus}
      onBlur={onInputBlur}
      onKeyDown={onInputKeyDown}
      ref={containerRef}
    >
      {children}
      {!children &&
        <div className={'placeholder'}>
          {placeholder ?? '\u00A0'}
        </div>
      }
      {arrowDropUp && arrowDropDown &&
        <div className={'suffix'}>
          {isPopoverOpen ? arrowDropUp : arrowDropDown}
        </div>
      }
    </div>
  );
}

AutoCompleteValueContainer.propTypes = {
  className: HoopsPropTypes.className,
  initialOpen: HoopsPropTypes.bool,
  children: HoopsPropTypes.children,
};

export function AutoCompleteTextInput({clearable, value, onClear}) {
  const {disabled, search, isPopoverOpen, openPopover, togglePopover, onInputFocus, onInputBlur, onInputKeyDown, onSearchChange, placeholder} = useAutoCompleteContext();
  const inputRef = useRef();

  const arrowDropDown = useMemo(() => <ArrowDropDown onClick={togglePopover} />, [togglePopover]);
  const arrowDropUp = useMemo(() => <ArrowDropUp onClick={togglePopover} />, [togglePopover]);

  const handleClear = useCallback(() => {
    onClear?.();
    inputRef.current.focus();
  }, [onClear]);

  return (
    <>
      <TextInput
        selectOnFocus
        disabled={disabled}
        value={isPopoverOpen ? search : value}
        clearable={clearable}
        onBlur={onInputBlur}
        onChange={onSearchChange}
        onClear={handleClear}
        onFocus={onInputFocus}
        onKeyDown={onInputKeyDown}
        onMouseDown={openPopover}
        suffix={isPopoverOpen ? arrowDropUp : arrowDropDown}
        placeholder={placeholder}
        inputRef={inputRef}
      />
    </>
  );
}

AutoCompleteTextInput.propTypes = {
  value: HoopsPropTypes.string,
  clearable: HoopsPropTypes.bool,
  onClear: HoopsPropTypes.func,
};

export function AutoCompletePopover({className, closeOnClick, noMinSize, placement, children}) {
  const {anchorEl, isPopoverOpen, closePopover, onPopoverClickAway, onPopoverShownChange} = useAutoCompleteContext();

  return (
    <ModelessPopover
      className={[className, 'autocomplete-popover', noMinSize && 'no-min-size']}
      anchorEl={anchorEl}
      closeOnClick={closeOnClick}
      open={isPopoverOpen && !!anchorEl}
      placement={placement ?? 'bottom-start'}
      onClose={closePopover}
      onClickAway={onPopoverClickAway}
      onShownChange={onPopoverShownChange}
    >
      {children}
    </ModelessPopover>
  );
}

AutoCompletePopover.propTypes = {
  className: HoopsPropTypes.string,
  closeOnClick: HoopsPropTypes.bool,
  noMinSize: HoopsPropTypes.bool,
  placement: HoopsPropTypes.string,
  children: HoopsPropTypes.children,
};

export function AutoCompleteList({emptyList, children}) {
  const {listElRef, loading} = useAutoCompleteContext();

  if (children == null || !children.length) {
    return (
      <ul className={loading ? 'autocomplete-list loading' : 'autocomplete-list empty-list'}>
        {loading &&
          <BusySpinner message={'Loading results'}/>
        }
        {!loading && emptyList &&
          emptyList
        }
        {!loading && !emptyList &&
          'No results to show'
        }
      </ul>
    );
  }
  return (
    <ul className={'autocomplete-list'} ref={listElRef}>
      {children}
    </ul>
  );
}

AutoCompleteList.propTypes = {
  emptyList: HoopsPropTypes.any,
  children: HoopsPropTypes.children,
};

export function AutoCompleteListItem({className, value, children}) {
  const {currentValue, highlightedValue, onListItemClick, selectedElRef} = useAutoCompleteContext();

  const selected = value === (highlightedValue ?? currentValue);

  return (
    <li
      className={classNames([className, selected && 'selected'])}
      onClick={onListItemClick}
      ref={selected ? selectedElRef : undefined}
      value={value}
    >
      {children}
    </li>
  );
}

AutoCompleteListItem.propTypes = {
  className: HoopsPropTypes.className,
  value: HoopsPropTypes.string,
  children: HoopsPropTypes.children,
};

export function AutoCompleteSearchBar({search, showLoading}) {
  const {loading} = useAutoCompleteContext();
  return (
    <Row className={'search-bar'} justifyCenter>
      {loading && showLoading &&
        <BusySpinner />
      }
      <span>Showing results for <b>{search || '""'}</b></span>
    </Row>
  );
}

AutoCompleteSearchBar.propTypes = {search: HoopsPropTypes.string,};

export function AutoCompleteEndButtons({children}) {
  return (
    <Row className={'end-buttons'} justifyCenter>
      {children}
    </Row>
  );
}

AutoCompleteEndButtons.propTypes = {children: HoopsPropTypes.children};

export function AutoCompleteHeading({heading}) {
  return (
    <HeadingText x14 className={'autocomplete-heading'}>
      {heading}
    </HeadingText>
  );
}

AutoCompleteHeading.propTypes = {heading: HoopsPropTypes.string};

import React from 'react';
import {DecimalInput} from '.';
import {HoopsPropTypes} from '../utils';

export function CurrencyInput({className, ...props}) {
  return <DecimalInput className={[className, 'currency-input currency-prefix']} minDecimals={2} maxDecimals={2} container {...props}/>;
}

export function CurrencyRateInput({className, ...props}) {
  return <DecimalInput className={[className, 'currency-input currency-prefix']} minDecimals={2} maxDecimals={6} container {...props}/>;
}

CurrencyInput.propTypes = CurrencyRateInput.propTypes = {
  className: HoopsPropTypes.className,
  clearable: HoopsPropTypes.bool,
  label: HoopsPropTypes.string,
  name: HoopsPropTypes.string,
  onChange: HoopsPropTypes.func,
  onClear: HoopsPropTypes.func,
  readOnly: HoopsPropTypes.bool,
  value: HoopsPropTypes.stringOrNumber,
};

// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import Grid from '@material-ui/core/Grid';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import XERO_LOGO from '../../assets/images/integrations/xero/Xero_software_logo.svg';
import ZAPIER_LOGO from '../../assets/images/integrations/zapier/zapier_logo.svg';
import SANMAR_LOGO from '../../assets/images/integrations/sanmar/sanmar-logo.svg';
import ALPHABRODER_LOGO from '../../assets/images/integrations/alphabroder/alphabroder-logo.svg';
import SSACTIVEWEAR_LOGO from '../../assets/images/integrations/ss_activewear/ss-activewear.svg';
import STRIPE_LOGO from '../../assets/images/integrations/stripe/stripe.svg';
import {getIntegrations} from './IntegrationActions';
import {IntegrationTile} from './IntegrationTile';
import XeroConnect from './XeroConnect';
import {ZapierButton, ZapierContent} from './Zapier';
import {featureFlagEnabled, featureFlags} from '../../utils/featureFlag';
import {PromoStandardButton} from './PromoStandardCard';
import {StripeButton} from './Stripe/StripeButton';
import {SageButton, SageContent} from './Sage';
import SAGE_LOGO from '../../assets/images/integrations/sage/SAGE-LOGO.png';
import {HoopsApiIntegrationTile, QuickbooksIntegrationTile} from './tiles';

const integrationTiles = [
  {
    name: 'QuickBooks',
    tile: QuickbooksIntegrationTile,
  }, {
    name: 'Xero',
    logo: XERO_LOGO,
    disabled: true,
    ContentComponent: XeroConnect
  }, {
    name: 'Zapier',
    type: 'zapier',
    logo: ZAPIER_LOGO,
    ContentComponent: ZapierContent,
    ActionComponent: ZapierButton,
  },
  ...(!featureFlagEnabled(featureFlags.promostandards_sanmar) ? [] : [{
    name: 'Sanmar',
    logo: SANMAR_LOGO,
    type: 'sanmar',
    ActionComponent: () => <PromoStandardButton vendorName={'Sanmar'}/>,
  }]),
  ...(!featureFlagEnabled(featureFlags.promostandards_alphabroder) ? [] : [{
    name: 'Alpha Broder',
    logo: ALPHABRODER_LOGO,
    type: 'alphabroder',
    region: ['CAN'], //only available for this region
    ActionComponent: () => <PromoStandardButton vendorName={'Alpha Broder'}  vendorNameTitle={'alphabroder (Canada)'}/>,
  }]),
  ...(!featureFlagEnabled(featureFlags.promostandards_alphabroder) ? [] : [{
    name: 'Alpha Broder US',
    logo: ALPHABRODER_LOGO,
    type: 'alphabroder',
    region: ['USA', 'AUS', 'UK', 'NZ', 'OTHER'],
    ActionComponent: () => <PromoStandardButton vendorName={'Alpha Broder US'} vendorNameTitle={'alphabroder US'}/>,
  }]),
  ...(!featureFlagEnabled(featureFlags.promostandards_ssactivewear) ? [] : [{
    name: 'S&S Activewear',
    logo: SSACTIVEWEAR_LOGO,
    type: 'ssactivewear',
    ActionComponent: () => <PromoStandardButton vendorName={'S&S Activewear'}/>,
  }]),
  ...(!featureFlagEnabled(featureFlags.stripe_integration_feature) ? [] : [{
    name: 'Stripe',
    logo: STRIPE_LOGO,
    type: 'stripe',
    ContentComponent: () => <p>Capture Payments Online.</p>,
    ActionComponent: () => <StripeButton/>,
  }]),
  {
    name: 'Sage',
    type: 'sage',
    logo: SAGE_LOGO,
    ContentComponent: SageContent,
    ActionComponent: SageButton,
  },
  {
    type: 'hoopsApi',
    tile: HoopsApiIntegrationTile,
  }
];

function Integrations() {
  const dispatch = useDispatch();
  const companySelector = (state) => state.companyReducer.company;
  const company = useSelector(companySelector);
  const integrationsSelector = (state) => state.integrationReducer.list.data;
  const integrations = useSelector(integrationsSelector);
  useEffect(() => {
    dispatch(getIntegrations());
  }, [dispatch]);

  const mappedIntegrations = integrationTiles.filter(({region}) => !region || (region.includes(company.region))).map((integrationTile) => ({
    ...integrationTile,
    config: integrations.find((integration) => integration.type === integrationTile.type)
  }));
  return (
    <Grid
      container
      justifyContent={'space-between'}
      alignItems={'center'}
      spacing={3}
    >
      <Grid item xs={12}>
        <h3>Integrations</h3>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3} style={{
          display: 'grid',
          gridTemplateColumns: '324px repeat(auto-fill, 324px)',
          gridAutoRows: '1fr',
        }}>
          {mappedIntegrations.map(({name, logo, disabled = false, ContentComponent, ActionComponent, config, type, tile}) => {
            if (tile) {
              const Tile = tile;
              return <Tile key={type} type={type} config={config}/>;
            } else {
              return <IntegrationTile key={name} name={name} logo={logo} disabled={disabled} ContentComponent={ContentComponent} ActionComponent={ActionComponent} config={config}/>;
            }
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Integrations;


import PropTypes from 'prop-types';

export const HoopsPropTypes = {
  ...PropTypes,
  arrayOfAny: PropTypes.arrayOf(PropTypes.any),
  arrayOfObject: PropTypes.arrayOf(PropTypes.object),
  arrayOfString: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.arrayOf(PropTypes.any)]),
  decorator: PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.string]),
  stringOrNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  boolOrString: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  stringOrBool: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

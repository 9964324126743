import {Delete as DeleteIcon} from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {Popover} from '@mui/material';
import React, {useState} from 'react';
import {Grid} from '../Layout';
import {registerGlobalStyle} from '../../theme';
import {formatDateTimeShort} from '../../utils';
import {BodyText} from '../Text';
import {Button} from '../Basic';

registerGlobalStyle('.auto-save-indicator', (theme) => ({
  alignItems: 'center',
  alignSelf: 'end',
  '.auto-save-indicator-icon': {color: theme.colors.palette.green},
  '.text-body': {lineHeight: 1.2, fontSize: '0.875rem'},
}));

registerGlobalStyle('.auto-save-indicator-popover', (theme) => ({
  '& .MuiPaper-root': {
    padding: theme.spacing(2),
    maxWidth: 300,
    '.text-body': {fontSize: '0.875rem'},
    '.action-button': {marginTop: theme.spacing(2),},
  }
}));

export const AutoSaveIndicator = ({autoSaveCreatedAt, onDelete}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    onDelete();
    handleClose();
  };

  const savedDate = formatDateTimeShort(autoSaveCreatedAt);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      {autoSaveCreatedAt &&
        <Grid gridTemplateColumns={'24px 1fr'} className={'auto-save-indicator'}>
          <CheckCircleIcon fontSize='small' className='auto-save-indicator-icon' />
          <BodyText>
            AutoSaved: {savedDate} <br />with
            <Button actionInline text=' unpublished changes.' onClick={handleClick} />
          </BodyText>
        </Grid>
      }
      <Popover
        className='auto-save-indicator-popover'
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <BodyText>You have unpublished changes. To save these changes to the document and set them live, click the SAVE button.</BodyText>
        <Button actionPrimary prefix={DeleteIcon} text='Delete unpublished changes.' onClick={handleDelete} />
      </Popover>
    </>
  );
};

import {uniq} from 'lodash';
import {
  asCurrencyRateRounded,
  asCurrencyRateString,
  asCurrencyRounded,
  asNumber,
  asPercentageRounded,
  asRounded,
  asRoundedString,
  bind,
  byLocaleCaseInsensitive,
  checkedFromEvent,
  listToMap,
  markupFromMargin,
  randomString,
  sortSizes,
  unbind,
  valueFromEvent
} from '../../../utils';
import {
  additionalCostSubstitutions,
  companySubstitutions,
  decorationSubstitutions,
  doTemplateSubstitution,
  nestSubstitutions,
  oneOffDecorationSubstitutions,
  oneOffProductSubstitutions,
  productSubstitutions,
  removeMissingSubstitutions,
  salesDocSubstitutions
} from '../../../models/TemplateSubstitutions';
import {getTaxTotal, Markups} from '../../../models';

export class SalesDoc {
  static Type = {
    CLASSIC_QUOTE: 'classic',
    NEW_QUOTE: 'quoteV2',
    TEMPLATE: 'template',
    PRESENTATION: 'presentation',
    PRESENTATION_TEMPLATE: 'presentationTemplate',
  };

  static emptyTemplate = {
    additionalCostDescription: '{{name}}',
    decorationDescription: '{{name}}',
    footerImage: null,
    headerImage: null,
    hideTotals: false,
    presentationAdditionalCostPriceMode: 'flatRate',
    presentationDecorationPriceMode: 'quantityBased',
    productDescription: '{{name}}',
    rollupAdditionalCostSellPrice: true,
    rollupDecorationSellPrice: true,
    rounding: 2,
    showAdditionalCostItems: true,
    showDeadline: false,
    showDecorationItems: true,
    showFooterBlock: false,
    showHeaderBlock: false,
    showPricing: true,
    showReference: false,
    showTitleBlock: true,
    showVariants: true,
    tax: null,
    titleImage: null,
    variantPriceMode: 'variant',

    acceptButtonColor: '#00CCF0',
    acceptButtonText: 'ACCEPT',
    acceptButtonEnabled: true,
    cartButtonColor: '#22CC96',
    cartButtonText: 'ADD TO CART',
    cartButtonEnabled: true,
    commentButtonColor: '#00CCF0',
    commentButtonText: 'LEAVE COMMENT',
    commentButtonEnabled: true,
    likeButtonColor: '#00CCF0',
    likeButtonEnabled: true,
    payButtonColor: '#22CC96',
    payButtonText: 'ACCEPT & PAY',
    payButtonEnabled: true,
    pdfButtonColor: '#00CCF0',
    pdfButtonText: 'DOWNLOAD PDF',
    pdfButtonEnabled: true,
  };

  static emptyContact = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    deleted: false
  };

  static emptyCustomer = {
    type: 'COMPANY',
    email: '',
    phone: '',
    website: '',
    contacts: [SalesDoc.emptyContact],
    addresses: [],
    settings: {whitelabelProofPortal: false},
    profile: '',
    customerRepId: null
  };

  static OnlinePaymentStatus = {
    SUCCESSFUL: 'successful',
    REJECTED: 'rejected',
    PENDING: 'pending',
  };

  newItem;
  newItemId;
  deletedItemIndex;
  _notify;
  summary;
  quantities;
  markups;
  defaultTax;

  _id;
  documentType = SalesDoc.Type.NEW_QUOTE;

  docTypeName;          // Doc type like Quote, Presentation etc
  docTypeDescription;   // Description of the doc type
  docTemplateName;      // Name of the template being used
  docTemplateId;        // _id of the template

  items;
  decorations;
  products;
  vendors;

  createdAt;
  createdBy;
  createdById;
  updatedAt;
  updatedBy;
  updatedById;

  accepted;
  acceptedAt;
  billingAddress;
  company;
  companyTradingEntityId;
  contactId;
  contact;
  customerId;
  customer;
  deadline;
  footerText;
  headerText;
  leadSource;
  number;
  ownerId;
  owner;
  reference;
  shippingAddress;
  status = 'CREATED';
  terms;
  title;
  titleText;
  viewed;

  subTotal;
  taxTotal;
  total;

  invoice;
  onlinePaymentStatus;
  onlinePaymentAmount;
  stripeInvoiceId;
  stripeInvoiceAmount;

  template;

  constructor(that) {
    if (that) {
      Object.assign(this, that);
      this.template = {...SalesDoc.emptyTemplate, ...that.template};
    }

    this.decorations = this.decorations ?? {};
    this.products = this.products ?? {};
    this.summary = this.summary ?? new SalesDocSummary();
    this.template = this.template ?? SalesDoc.emptyTemplate;
    this.vendors = this.vendors ?? {};

    if (this.isPresentation()) {
      // The cart and PDF button don't work at the moment, but we can't just disable them in the
      // guest view, because we don't want them to suddenly appear on old presentations once the
      // feature is available.
      this.template.cartButtonEnabled = false;
      this.template.pdfButtonEnabled = false;
    }

    bind(this);

    if (!this.items || !Array.isArray(this.items)) {
      this.items = [new SalesDocItem({type: SalesDocItem.Type.GROUP_PLACEHOLDER})];
    } else if (this.items.length === 0) {
      this.items.push(new SalesDocItem({type: SalesDocItem.Type.GROUP_PLACEHOLDER}));
    }

    // The items need to have a reference to the doc, so they update the list as needed
    this.items.forEach((item) => item.doc = this);
  }

  // Make a copy of the document, with some changes
  copyWith(that) {
    return new SalesDoc({...this, ...that, template: {...this.template, ...that.template}});
  }

  static createEmpty(user, {presentation} = {}) {
    return new SalesDoc({
      ...this.emptyUser(user),
      newItem: undefined,
      newItemId: undefined,
      deletedItemIndex: undefined,
      documentType: presentation ? SalesDoc.Type.PRESENTATION : SalesDoc.Type.NEW_QUOTE,
    });
  }

  static emptyUser(user) {
    const now = new Date();
    return {
      createdAt: now,
      createdBy: user,
      createdById: user?._id,
      updatedAt: now,
      updatedBy: user,
      updatedById: user?._id,
      owner: user,
      ownerId: user?._id,
    };
  }

  // Will notify and cause a state change
  notify(options = {}) {
    if (this._notify) {
      this._notify(this, options);
    }
    return this;
  }

  setNotify(notify) {
    this._notify = notify;
    return this;
  }

  // Convert the SalesDoc from the back end format to what we use in the front end
  static fromApi(apiSalesDoc, {cleanDuplicate, cleanConvert} = {}) {
    if (!apiSalesDoc || !apiSalesDoc.number) {
      return apiSalesDoc;
    }

    const vendors = listToMap(apiSalesDoc.vendors);

    const newSalesDoc = new SalesDoc({
      ...apiSalesDoc,
      decorations: {},
      products: {},
      vendors,
      summary: null,
      ...(!cleanDuplicate ? {} : {
        // Clean out fields additional fields for duplicate, most are done by the backend
        _id: null,
        number: null,
      }),
      ...(!cleanConvert || apiSalesDoc.documentType !== SalesDoc.Type.PRESENTATION ? {} : {
        // Clean out the template when converting a Presentation to a SalesDoc
        documentType: SalesDoc.Type.NEW_QUOTE,
        documentTypeOrigin: apiSalesDoc.documentType,
        docTypeName: undefined,
        docTypeDescription: undefined,
        docTemplateName: undefined,
        docTemplateId: undefined,
      }),
      items: apiSalesDoc.items.map((item) => new SalesDocItem({
        ...item,
        vendor: vendors[item.vendorId],
        vendorName: vendors[item.vendorId]?.name,
        additionalCost: asCurrencyRateRounded(item.additionalCost),
        buyPrice: asCurrencyRateRounded(item.buyPrice),
        markup: asPercentageRounded(item.markup),
        quantity: asNumber(item.quantity),
        sellPrice: asCurrencyRateRounded(item.sellPrice),
        unitPrice: asCurrencyRateRounded(item.unitPrice),
        colors: apiSalesDoc.documentType !== SalesDoc.Type.PRESENTATION || cleanConvert ? undefined : item.colors,
        images: apiSalesDoc.documentType !== SalesDoc.Type.PRESENTATION || cleanConvert ? undefined : item.images,
        sizes: apiSalesDoc.documentType !== SalesDoc.Type.PRESENTATION || cleanConvert ? undefined : item.sizes,
      }))
    })
      .addVendorsFromApi(apiSalesDoc.vendors)
      .addDecorationsFromApi(apiSalesDoc.decorations)
      .addProductsFromApi(apiSalesDoc.products);

    if (cleanConvert && apiSalesDoc.documentType === SalesDoc.Type.PRESENTATION) {
      const newItems = [];
      newSalesDoc.getGroupIds().forEach((groupId) => {
        const groupIds = {};
        newSalesDoc.getPresentationRowIdsInGroup(groupId).forEach((rowId) => {
          const items = newSalesDoc.getVariantItems(rowId);
          items.forEach((item, index) => {
            groupIds[index] = groupIds[index] ?? SalesDoc.newGroupId();
            newItems.push(item.copyWith({
              groupId: groupIds[index],
              variantId: item.type === SalesDocItem.Type.VARIANT ? SalesDoc.newVariantId() : undefined,
              unitPriceOverride: true,
              quantity: item.type === SalesDocItem.Type.ADDITIONAL_COST ? 1 : item.quantity,
            }));
          });
        });
      });
      newSalesDoc.items = newItems;
    }

    return newSalesDoc.recalculate();
  }

  // Return the SalesDoc in the format expected by the back end
  forApi() {
    const serialized = this.copyWith({
      documentTypeOrigin: undefined,
      footerText: this.getSubstitutedText('footerText'),
      headerText: this.getSubstitutedText('headerText'),
      titleText: this.getSubstitutedText('titleText'),
      items: this.items
        .map((item) => ({
          ...item,
          description: item.getSubstitutedDescription(),
        }))
    })
      .#serialize();

    Object.assign(serialized, {
      contact: this.contact && {
        firstName: this.contact.firstName,
        lastName: this.contact.lastName,
        email: this.contact.email,
        phone: this.contact.phone,
      },
      customer: this.customer && {
        name: this.customer.name,
        email: this.customer.email,
        phone: this.customer.phone,
      },
      owner: {
        firstName: this.owner.firstName,
        lastName: this.owner.lastName,
        username: this.owner.username,
      },
      decorations: uniq(this.items.map(({decoration}) => decoration).filter(Boolean)).map(({name, category}) => ({name, category})),
      products: uniq(this.items.map(({product}) => product).filter(Boolean)).map(({title, code, brand}) => ({title, code, brand})),
      vendors: uniq(this.items.map(({vendor}) => vendor).filter(Boolean)).map(({name, email}) => ({name, email})),
      items: serialized.items.filter(({type}) => !type.includes('Placeholder')),
    });

    return serialized;
  }

  // Unpack autoSave salesDoc and format for use in the front end
  static fromAutoSave(salesDoc, user) {
    if (!salesDoc) {
      return salesDoc;
    }

    // Convert the list of products, decorations, and vendors to maps
    const products = listToMap(salesDoc.products);
    const decorations = listToMap(salesDoc.decorations);
    const vendors = listToMap(salesDoc.vendors);

    return SalesDoc.createEmpty(user).copyWith({
      ...salesDoc,
      customer: undefined,
      products: {},
      decorations,
      vendors,
      items: salesDoc.items.map((item) => new SalesDocItem({
        ...item,
        product: products[item.productId],
        decoration: decorations[item.decorationId],
        vendor: vendors[item.vendorId],
      })),
    })
      .addProductsFromApi(salesDoc.products)
      .addCustomerFromApi(salesDoc.customer)
      .recalculate();
  }

  // Pack salesDoc to be stored as autoSave record in local storage
  forAutoSave() {
    const serialized = this.#serialize();

    Object.assign(serialized, {
      contact: this.contact && {
        firstName: this.contact.firstName,
        lastName: this.contact.lastName,
        email: this.contact.email,
        phone: this.contact.phone,
      },
      customer: this.customer && {
        _id: this.customer._id,
        name: this.customer.name,
        email: this.customer.email,
        phone: this.customer.phone,
      },
      decorations: this.decorations && Object.entries(this.decorations).map(([key, value]) => ({_id: value._id ?? key})),
      products: this.products && Object.entries(this.products).map(([key, value]) => ({_id: value._id ?? key})),
      vendors: this.vendors && Object.entries(this.vendors).map(([key, value]) => ({_id: value._id ?? key})),
    });

    return serialized;
  }

  // Initialize from a template
  static fromTemplate(template, user) {
    return new SalesDoc({
      ...template,
      template: {...template.template},
      _id: null,
      footerText: template.footerText,
      headerText: template.headerText,
      titleText: template.titleText,

      docTemplateId: template._id,
      documentType: template.documentType === SalesDoc.Type.PRESENTATION_TEMPLATE || template.documentType === SalesDoc.Type.PRESENTATION
        ? SalesDoc.Type.PRESENTATION : SalesDoc.Type.NEW_QUOTE,
    }).copyWith(SalesDoc.emptyUser(user))
      .recalculate();
  }

  switchTemplate(template) {
    return this.copyWith({
      template: {...template.template},
      docTypeName: template.docTypeName,
      docTypeDescription: template.docTypeDescription,
      docTemplateName: template.docTemplateName,
      docTemplateId: template._id,
      description: template.description,
      terms: template.terms,
    })
      .recalculate()
      .notify();
  }

  // Return the SalesDoc as a template
  forTemplate() {
    const template = new SalesDoc({
      _id: undefined,
      docTemplateId: this.docTemplateId,
      documentType: this.documentType === SalesDoc.Type.PRESENTATION ? SalesDoc.Type.PRESENTATION_TEMPLATE : SalesDoc.Type.TEMPLATE,
      documentTypeOrigin: undefined,
      createdAt: undefined,
      createdById: undefined,
      docTypeName: this.docTypeName,
      docTypeDescription: this.docTypeDescription,
      docTemplateName: this.docTemplateName,
      template: this.template,
      footerText: this.footerText,
      headerText: this.headerText,
      titleText: this.titleText,

      terms: this.terms,
    }).#serialize();

    delete template.items;

    return template;
  }

  #serialize() {
    return {
      ...unbind(this),
      newItem: undefined,
      newItemId: undefined,
      deletedItemIndex: undefined,
      _notify: undefined,
      summary: undefined,
      quantities: undefined,
      markups: undefined,
      defaultTax: undefined,
      decorations: undefined,
      products: undefined,
      vendors: undefined,

      customer: undefined,
      contact: undefined,
      owner: undefined,

      createdBy: undefined,
      updatedBy: undefined,

      items: this.items.map((item) => ({
        ...unbind(item),
        buyPricePerUnit: undefined,
        doc: undefined,
        decoration: undefined,
        product: undefined,
        minQuantity: undefined,
        isAveragedPrice: undefined,
        unitPriceCalc: undefined,
        rolledUpCost: undefined,
        rolledUpUnitPrice: undefined,
        vendor: undefined,
        vendorName: undefined,
      })),
    };
  }

  setCompany(company) {
    // Company is not part of the state, so don't notify or recalculate
    this.company = company;

    if (this.isPresentation() && !this.template.titleImage) {
      this.template.titleImage = company.logo ?? company.companyTradingEntities?.[0]?.logo;
    }

    this.defaultTax = company.taxes.find((tax) => tax.type === 'revenue' && tax.isDefault);

    return this;
  }

  setMarkups(markups) {
    // Markups are not part of the state, so don't notify or recalculate
    this.markups = markups;
    return this;
  }

  // Add a customer from the back end, the customer will be ignored if it does not have the correct ID
  addCustomerFromApi(customer) {
    if (this.customer !== customer && this.customerId === customer._id) {
      return this.copyWith({customer, customerId: customer?._id});
    }
    return this;
  }

  // Add a decoration or array of decorations from the back end
  addDecorationsFromApi(apiDecorations) {
    if (!apiDecorations || apiDecorations.length === 0) {
      return this;
    }
    if (!Array.isArray(apiDecorations)) {
      apiDecorations = [apiDecorations];
    }

    const decorations = {};
    const vendors = [];
    apiDecorations.forEach((decoration) => {
      if (decoration.vendor) {
        vendors.push(decoration.vendor);
      }
      decorations[decoration._id] = decoration;
    });

    // Update any items that reference the decoration to use the new decoration
    const items = this.items.map((item) => decorations[item.decorationId] == null ? item : item.copyWith({
      decoration: decorations[item.decorationId],
      productId: decorations[item.decorationId].productId
    }));

    // Create a new document with updated items, products and vendors
    return this.copyWith({
      items,
      decorations: {...this.decorations, ...decorations},
    }).addVendorsFromApi(vendors);
  }

  // Add a product or array of products from the back end
  addProductsFromApi(apiProducts) {
    if (!apiProducts || apiProducts.length === 0) {
      return this;
    }
    if (!Array.isArray(apiProducts)) {
      apiProducts = [apiProducts];
    }

    // Convert each product to the expected format, and track the new products and vendors
    const products = {};
    const vendors = [];
    apiProducts.forEach((product) => {
      const colorsMap = {};
      const sizesMap = {};
      const variantImagesMap = {};

      product.variants?.filter(Boolean).forEach((variant) => {
        if (colorsMap[variant.color] == null) {
          colorsMap[variant.color] = variant.color;
        }
        if (sizesMap[variant.size] == null) {
          sizesMap[variant.size] = variant.size;
        }
        if (variantImagesMap[variant.color] == null && variant.image) {
          variantImagesMap[variant.color] = variant.image;
        }
      });

      // For Sage products get the images and colors
      const namedImagesMap = product.extensions?.pics.reduce((acc, {caption, index, url}) => ({...acc, [caption || index.toString()]: url}), {}) ?? [];
      if (!product.variants?.length) {
        product.extensions?.colors?.split(/\s*,\s*/).forEach((color) => colorsMap[color] = color);
      }

      // Sort the colors alphabetically, then create a new color map that has the keys sorted alphabetically as well
      const colors = Object.keys(colorsMap).toSorted(byLocaleCaseInsensitive);
      const names = uniq([...colors, ...Object.keys(namedImagesMap)]).toSorted(byLocaleCaseInsensitive);
      const namedImages = names.reduce((acc, name) => {
        const image = variantImagesMap[name] || namedImagesMap[name];
        if (image) {
          acc[name] = image;
        }
        return acc;
      }, {});

      if (product.vendor) {
        vendors.push(product.vendor);
      }

      products[product._id] = {
        variants: [],
        ...product,
        colors,
        sizes: sortSizes(Object.keys(sizesMap)),
        namedImages,
        primaryImage: Object.values(namedImages).includes(product.primaryImage) ? null : product.primaryImage
      };
    });

    // Update any items that reference the product to use the new product and possibly vendor
    const items = this.items.map((item) => {
      if (products[item.productId] == null) {
        return item;
      } else {
        const product = products[item.productId];
        const primaryImageList = product.primaryImage ? [product.primaryImage] : [];
        return item.copyWith({
          product,
          image: item.image || (item.type !== SalesDocItem.Type.VARIANT ? null : (product.primaryImage || Object.values(product.namedImages ?? {})[0])),
          ...(!this.isPresentation() ? {} : {
            colors: item.colors ?? (product.colors?.length > 0 ? [...product.colors] : undefined),
            sizes: item.sizes ?? (product.sizes?.length > 0 ? [...product.sizes] : undefined),
            images: item.images ?? (product.namedImages && Object.values(product.namedImages).length > 0
              ? uniq([...primaryImageList, ...Object.values(product.namedImages)])
              : primaryImageList),
          }),
        });
      }
    });

    // Create a new document with updated items, products and vendors
    return this.copyWith({
      items,
      products: {...this.products, ...products},
    })
      .addVendorsFromApi(vendors);
  }

  addVendorsFromApi(vendors) {
    if (!vendors) {
      return this;
    }
    if (!Array.isArray(vendors)) {
      vendors = [vendors];
    }
    vendors = vendors.filter((vendor) => vendor._id && this.vendors[vendor._id]?.name == null);
    if (vendors.length > 0) {
      vendors = listToMap(vendors);
      return this.copyWith({
        vendors: {...this.vendors, ...vendors},
        items: this.items.map((item) => {
          const vendor = vendors[item.vendorId];
          return vendor ? item.copyWith({vendor, vendorName: vendor.name}) : item;
        }),
      });
    }
    return this;
  }

  // Getters

  getAllCategories() {
    const catSet = this.items.reduce((acc, {categories}) => {
      categories?.forEach((category) => { acc[category] = true; });
      return acc;
    }, {});
    return Object.keys(catSet).toSorted(byLocaleCaseInsensitive);
  }

  getFirstItemInGroup(groupId) {
    return this.items.find((item) => item.groupId === groupId);
  }

  getFirstProductVariantItemInGroup(groupId) {
    return this.items.find((item) => item.groupId === groupId && item.isProductVariant());
  }

  getFirstVariantItem(variantId) {
    return this.items.find((item) => variantId != null && item.variantId === variantId);
  }

  getGroupIds() {
    return Object.keys(this.items.reduce((acc, {groupId}) => ({...acc, [groupId]: true}), {}));
  }

  getItem(itemId) {
    return itemId ? this.items.find((item) => item.itemId === itemId) : null;
  }

  getItemsInGroup(groupId) {
    return this.items.filter((item) => item.groupId === groupId);
  }

  getLastItemInGroup(groupId) {
    return this.items.findLast((item) => item.groupId === groupId);
  }

  getMainItemInGroup(groupId) {
    return this.getFirstProductVariantItemInGroup(groupId) ?? this.getItemsInGroup(groupId);
  }

  getNonProductVariantsInGroup(groupId) {
    return this.items.filter((item) => item.groupId === groupId && item.type !== SalesDocItem.Type.VARIANT && item.type !== SalesDocItem.Type.PRODUCT_PLACEHOLDER);
  }

  getPresentationRowIdsInGroup(groupId) {
    return Object.keys(
      this.items.filter((item) => item.groupId === groupId && item.variantId != null)
        .reduce((acc, {variantId}) => ({...acc, [variantId]: variantId}), {})
    );
  }

  getProductsInGroup(groupId) {
    return this.items.filter((item) => item.groupId === groupId && item.type === SalesDocItem.Type.VARIANT && item.productId != null).map((item) => item.product);
  }

  getProductVariantsInGroup(groupId) {
    return this.items.filter((item) => item.groupId === groupId && (item.type === SalesDocItem.Type.VARIANT || item.type === SalesDocItem.Type.PRODUCT_PLACEHOLDER));
  }

  getProductVariantIdsInGroup(groupId) {
    return Object.keys(
      this.items.filter((item) => item.groupId === groupId && (item.type === SalesDocItem.Type.VARIANT || item.type === SalesDocItem.Type.PRODUCT_PLACEHOLDER))
        .reduce((acc, {variantId}) => ({...acc, [variantId]: variantId}), {})
    );
  }

  getRounding() {
    return this.template.rounding ?? 2;
  }

  // Helper for template substitution
  getSubstitutedText(fieldName) {
    const substitutions = nestSubstitutions({salesDoc: salesDocSubstitutions, company: companySubstitutions});
    const fields = {salesDoc: {...this, number: this.number ? this.number : '{{number}}'}, company: this.company};
    return doTemplateSubstitution(this[fieldName], substitutions, fields, true);
  }

  getVariantItems(variantId) {
    return this.items.filter((item) => variantId != null && item.variantId === variantId);
  }

  groupHasProductVariants(groupId) {
    return this.items.some((item) => item.groupId === groupId && item.type === SalesDocItem.Type.VARIANT);
  }

  isPresentation() {
    return this.documentType === SalesDoc.Type.PRESENTATION;
  }

  // Setters and adders --- these will update state

  accept() {
    return this.copyWith({
      accepted: true,
      acceptedAt: new Date(),
      status: 'ACCEPTED',
    }).notify();
  }

  addDecoration(decoration) {
    if (this.decorations[decoration._id] == null) {
      return this.copyWith({decorations: {...this.decorations, [decoration._id]: decoration}})
        .addVendorsFromApi(decoration.vendor)
        .syncPresentationColumns()
        .recalculate()
        .notify();
    }
    return this.recalculate().notify();
  }

  addGroup(afterItem) {
    // Adding a group placeholder does not trigger a change
    if (afterItem) {
      return this.addItemAfter(afterItem, new SalesDocItem({type: SalesDocItem.Type.GROUP_PLACEHOLDER})).notify({noChange: true});
    } else {
      return this.addItem(new SalesDocItem({type: SalesDocItem.Type.GROUP_PLACEHOLDER})).notify({noChange: true});
    }
  }

  addPresentationGroupColumn(groupId, quantity) {
    const rowIds = this.getPresentationRowIdsInGroup(groupId);
    const items = [...this.items];
    rowIds.forEach((rowId) => {
      // Find the item to copy and the index to insert at. Adding a new row is a special case, and we insert
      // after the last item in the row, so find the last item and add 1 to the insert position.
      const refItem = items.find((item) => item.variantId === rowId && item.quantity >= quantity)
        ?? items.findLast((item) => item.variantId === rowId);
      const insertPos = items.indexOf(refItem) + (refItem.quantity >= quantity ? 0 : 1);
      items.splice(insertPos, 0, refItem.copyWith({itemId: undefined, quantity}));
    });
    return this.copyWith({items})
      .recalculate()
      .notify();
  }

  addProduct(product) {
    return this.addProductsFromApi(product)
      .syncPresentationColumns()
      .recalculate()
      .notify();
  }

  addSageProducts(afterItem, products) {
    const newItems = products.map((product) => new SalesDocItem({
      ...SalesDocItem.initVariantProps({
        salesDoc: this,
        type: SalesDocItem.Type.VARIANT,
        groupId: this.isPresentation() || !afterItem ? SalesDoc.newGroupId() : afterItem.groupId,
        catalog: 'sage',
      }),
      ...SalesDocItem.initVariantPropsFromProduct({salesDoc: this, product}),
    }));

    const replaceCount = afterItem.type === SalesDocItem.Type.GROUP_PLACEHOLDER ? 1 : 0;
    const insertPos = this.items.indexOf(afterItem) + 1 - replaceCount;
    return this.spliceItems(insertPos, replaceCount, ...newItems)
      .addProductsFromApi(products)
      .syncPresentationColumns()
      .recalculate()
      .notify();
  }

  addVendor(vendor) {
    return this.addVendorsFromApi(vendor).notify();
  }

  clearMarkupsOnVariants(variantId) {
    return this.copyWith({items: this.items.map((item) => variantId && item.variantId === variantId && !item.markupOverride ? item.copyWith({markup: null}) : item)});
  }

  copyGroup(groupId) {
    const items = this.getItemsInGroup(groupId);
    const newGroupId = SalesDoc.newGroupId();
    const newVariantIds = items.reduce((acc, item) => ({...acc, [item.variantId]: SalesDoc.newVariantId()}), {});
    const newItemIds = items.reduce((acc, item) => ({...acc, [item.itemId]: SalesDoc.newItemId()}), {});
    items.forEach((item) => {
      // When copying a group, we need to make sure that the correct IDs are assigned to any additional costs
      // associated with decorations
      if (item.isDecorationSetupCost()) {
        const decorationItemId = item.getDecorationItemId();
        newItemIds[item.itemId] = SalesDoc.makeDecorationSetupCostId(newItemIds[decorationItemId]);
      }
    });
    return this.addItemsAfter(items[items.length - 1], items.map((item) => item.copyWith({
      groupId: newGroupId,
      itemId: newItemIds[item.itemId],
      variantId: item.variantId ? newVariantIds[item.variantId] : undefined,
    }))).recalculate().notify();
  }

  copyVariants(variantId) {
    const newVariantId = SalesDoc.newVariantId();
    const items = this.getVariantItems(variantId);
    const newItems = items.map((item) => item.copyWith({
      itemId: undefined,
      variantId: newVariantId,
    }));
    if (items[0]?.isDecoration()) {
      // For decorations, we have to copy the setup cost as well as the decoration itself
      const newSetupCostVariantId = SalesDoc.newVariantId();
      items.forEach((item, index) => {
        const setupCostItem = item.getSetupCostItem();
        if (setupCostItem) {
          newItems.push(setupCostItem.copyWith({
            itemId: SalesDoc.makeDecorationSetupCostId(newItems[index].itemId),
            variantId: newSetupCostVariantId,
          }));
        }
      });
    }
    return this.addItemsAfter(items[items.length - 1], newItems).recalculate().notify();
  }

  deleteGroup(groupId) {
    return this.copyWith({
      items: this.items.filter((item) => item.groupId !== groupId),
      deletedItemIndex: this.items.indexOf(this.getFirstItemInGroup(groupId)),
    }).recalculate().notify();
  }

  deletePresentationGroupColumn(groupId, columnIndex) {
    const rowIds = this.getPresentationRowIdsInGroup(groupId);
    const items = [...this.items];
    rowIds.forEach((rowId) => {
      const rowItems = this.getVariantItems(rowId);
      if (columnIndex >= 0 && columnIndex < rowItems.length) {
        items.splice(items.indexOf(rowItems[columnIndex]), 1);
      }
    });
    return this.copyWith({items})
      .recalculate()
      .notify();
  }

  deleteVariants(variantId) {
    // If this is a decoration with a setup cost, delete the setup costs as well
    let setupCostVariantId;
    const firstVariant = this.getFirstVariantItem(variantId);
    if (firstVariant?.isDecoration()) {
      setupCostVariantId = firstVariant.getSetupCostItem()?.variantId;
    }
    return this.copyWith({
      items: this.items.filter((item) => item.variantId !== variantId && (setupCostVariantId == null || item.variantId !== setupCostVariantId)),
      deletedItemIndex: this.items.indexOf(this.getFirstVariantItem(variantId)),
    }).recalculate().notify();
  }

  moveGroup({afterItem, groupId}) {
    const group = this.getItemsInGroup(groupId);
    const itemsWithGroupRemoved = this.items.filter((el) => el.groupId !== groupId);
    return this.copyWith({items: itemsWithGroupRemoved})
      .spliceItems(afterItem ? itemsWithGroupRemoved.indexOf(afterItem) + 1 : 0, 0, ...group)
      .recalculate()
      .notify();
  }

  moveItemToGroup({afterItem, destinationGroupId, itemId}) {
    const movingItem = this.getItem(itemId);
    const itemsWithItemRemoved = this.items.filter((el) => el.itemId !== itemId);
    const docIndex = afterItem ? itemsWithItemRemoved.indexOf(afterItem) + 1 : itemsWithItemRemoved.findIndex((item) => item.groupId === destinationGroupId);
    return this.copyWith({items: itemsWithItemRemoved})
      .spliceItems(docIndex, 0, movingItem.copyWith({groupId: destinationGroupId}))
      .recalculate()
      .notify();
  }

  moveVariantsToGroup({afterItem, destinationGroupId, variantId}) {
    const variants = this.getVariantItems(variantId);
    const itemsWithVariantsRemoved = [...this.items.filter((el) => el.variantId !== variantId)];
    const docIndex = afterItem ? itemsWithVariantsRemoved.indexOf(afterItem) + 1 : itemsWithVariantsRemoved.findIndex((item) => item.groupId === destinationGroupId);
    return this.copyWith({items: itemsWithVariantsRemoved})
      .spliceItems(docIndex, 0, ...variants.map((item) => item.copyWith({groupId: destinationGroupId})))
      .recalculate()
      .notify();
  }

  removeCategory(category) {
    return this.copyWith({
      items: this.items.map((item) => {
        const pos = item.categories?.indexOf(category);
        if (pos >= 0) {
          return item.copyWith({categories: item.categories.toSpliced(pos, 1)});
        } else {
          return item;
        }
      })
    }).notify();
  }

  renameCategory(oldCategory, newCategory) {
    return this.copyWith({
      items: this.items.map((item) => {
        if (item.categories?.includes(oldCategory)) {
          return item.copyWith({categories: item.categories.map((category) => category === oldCategory ? newCategory : category).toSorted(byLocaleCaseInsensitive)});
        } else {
          return item;
        }
      })
    }).notify();
  }

  setBillingAddress(...billingAddressId) {
    billingAddressId = valueFromEvent(...billingAddressId);
    if (billingAddressId !== this.billingAddress?._id) {
      const billingAddress = this.customer?.addresses.find((address) => address.label === 'BILLING' && address._id === billingAddressId);
      if (billingAddress) {
        return this.copyWith({billingAddress}).notify();
      }
    }
    return this;
  }

  setCompanyTradingEntityId(companyTradingEntityId) {
    if (this.companyTradingEntityId !== companyTradingEntityId) {
      const newEntity = this.company.companyTradingEntities.find(({_id}) => _id === companyTradingEntityId);
      if (newEntity) {
        let headerImage = this.template.headerImage;
        const oldEntity = this.company.companyTradingEntities.find(({_id}) => _id === this.companyTradingEntityId) ?? this.company.companyTradingEntities[0];
        if (oldEntity?.logo === headerImage) {
          headerImage = newEntity.logo ?? this.company.companyTradingEntities[0].logo;
        }
        return this.copyWith({
          companyTradingEntityId,
          template: {
            ...this.template,
            headerImage,
          }
        }).notify();
      }
    }
    return this;
  }

  // This actually sets the contact and the customer
  setContact(contact) {
    if (this.contact !== contact || this.contactId !== contact?._id) {
      const customer = contact.customer ?? this.customer;
      let billingAddress = this.billingAddress;
      let shippingAddress = this.shippingAddress;
      if (customer && customer.addresses) {
        if (!customer.addresses.find(({_id}) => _id === billingAddress?._id)) {
          billingAddress = customer.addresses.find(({label}) => label === 'BILLING');
        }
        if (!customer.addresses.find(({_id}) => _id === shippingAddress?._id)) {
          shippingAddress = customer.addresses.find(({label}) => label === 'SHIPPING');
        }
      }
      return this.copyWith({contact, contactId: contact?._id, customer, customerId: customer?._id, billingAddress, shippingAddress}).notify();
    }
    return this;
  }

  setContactId(...contactId) {
    contactId = valueFromEvent(...contactId);
    if (contactId !== this.contactId && this.customer) {
      const contact = this.customer.contacts?.find(({_id}) => _id === contactId);
      if (contact) {
        return this.copyWith({contact, contactId}).notify();
      }
    }
    return this;
  }

  setDeadline(deadline) {
    if (deadline !== this.deadline || deadline?.getTime() !== this.deadline?.getTime()) {
      return this.copyWith({deadline}).notify();
    }
    return this;
  }

  setDocTemplateName(...docTemplateName) {
    docTemplateName = valueFromEvent(...docTemplateName);
    if (docTemplateName !== this.docTemplateName) {
      return this.copyWith({docTemplateName}).notify();
    }
    return this;
  }

  setDocTemplateId(...docTemplateId) {
    docTemplateId = valueFromEvent(...docTemplateId);
    if (docTemplateId !== this.docTemplateId) {
      return this.copyWith({docTemplateId}).notify();
    }
    return this;
  }

  setDocTypeDescription(...docTypeDescription) {
    docTypeDescription = valueFromEvent(...docTypeDescription);
    if (docTypeDescription !== this.docTypeDescription) {
      return this.copyWith({docTypeDescription}).notify();
    }
    return this;
  }

  setDocTypeName(...docTypeName) {
    docTypeName = valueFromEvent(...docTypeName);
    if (docTypeName !== this.docTypeName) {
      return this.copyWith({docTypeName}).notify();
    }
    return this;
  }

  setFooterBlockText(...value) {
    value = valueFromEvent(...value);
    if (value !== this.template.footerText) {
      return this.copyWith({footerText: value, template: {...this.template, footerText: value}}).notify();
    }
    return this;
  }

  setHeaderBlockText(...value) {
    value = valueFromEvent(...value);
    if (value !== this.template.headerText) {
      return this.copyWith({headerText: value, template: {...this.template, headerText: value}}).notify();
    }
    return this;
  }

  setTitleBlockText(...value) {
    value = valueFromEvent(...value);
    if (value !== this.template.titleText) {
      return this.copyWith({titleText: value, template: {...this.template, titleText: value}}).notify();
    }
    return this;
  }

  setOwner(owner) {
    if (owner && owner._id !== this.ownerId) {
      return this.copyWith({owner, ownerId: owner._id}).notify();
    }
    return this;
  }

  setPresentationGroupColumnQuantity(itemOrGroupId, quantity, columnIndex) {
    if (itemOrGroupId instanceof SalesDocItem) {
      const rowItems = this.getVariantItems(itemOrGroupId.variantId);
      columnIndex = rowItems.indexOf(itemOrGroupId);
      itemOrGroupId = itemOrGroupId.groupId;
    }
    const rowIds = this.getPresentationRowIdsInGroup(itemOrGroupId);
    const items = [...this.items];
    rowIds.forEach((rowId) => {
      const rowItems = this.getVariantItems(rowId);
      if (columnIndex >= 0 && columnIndex < rowItems.length) {
        const item = rowItems[columnIndex];
        items[items.indexOf(item)] = item.copyWith({
          quantity,
          markup: item.markupOverride ? item.markup : null,
        });
      }
    });
    return this.copyWith({items})
      .recalculate()
      .notify();
  }

  setReference(...reference) {
    reference = valueFromEvent(...reference);
    if (reference !== this.reference) {
      return this.copyWith({reference}).notify();
    }
    return this;
  }

  setRounding(...value) {
    return this.#updateTemplate('rounding', ...value).recalculate().notify();
  }

  setShippingAddress(...shippingAddressId) {
    shippingAddressId = valueFromEvent(...shippingAddressId);
    if (shippingAddressId !== this.shippingAddress?._id) {
      const shippingAddress = this.customer?.addresses.find((address) => address.label === 'SHIPPING' && address._id === shippingAddressId);
      if (shippingAddress) {
        return this.copyWith({shippingAddress}).notify();
      }
    }
    return this;
  }

  setTerms(...terms) {
    terms = valueFromEvent(...terms);
    if (terms !== this.terms) {
      return this.copyWith({terms}).notify();
    }
    return this;
  }

  // Template functions
  setTemplateAcceptButtonColor(...value) {
    return this.#updateTemplate('acceptButtonColor', ...value);
  }

  setTemplateAcceptButtonEnabled(...value) {
    return this.#updateTemplate('acceptButtonEnabled', ...value);
  }

  setTemplateAcceptButtonText(...value) {
    return this.#updateTemplate('acceptButtonText', ...value);
  }

  setTemplateAdditionalCostDescription(...value) {
    return this.#updateTemplate('additionalCostDescription', ...value);
  }

  setTemplateFooterBlockImage(...value) {
    return this.#updateTemplate('footerImage', ...value);
  }

  setTemplateHeaderBlockImage(...value) {
    return this.#updateTemplate('headerImage', ...value);
  }

  setTemplateTitleBlockImage(...value) {
    return this.#updateTemplate('titleImage', ...value);
  }

  setTemplateCartButtonColor(...value) {
    return this.#updateTemplate('cartButtonColor', ...value);
  }

  setTemplateCartButtonEnabled(...value) {
    return this.#updateTemplate('cartButtonEnabled', ...value);
  }

  setTemplateCartButtonText(...value) {
    return this.#updateTemplate('cartButtonText', ...value);
  }

  setTemplateCommentButtonColor(...value) {
    return this.#updateTemplate('commentButtonColor', ...value);
  }

  setTemplateCommentButtonEnabled(...value) {
    return this.#updateTemplate('commentButtonEnabled', ...value);
  }

  setTemplateCommentButtonText(...value) {
    return this.#updateTemplate('commentButtonText', ...value);
  }

  setTemplateDecorationDescription(...value) {
    return this.#updateTemplate('decorationDescription', ...value);
  }

  setTemplateHideTotals(...value) {
    return this.#updateTemplate('hideTotals', ...value);
  }

  setTemplateLikeButtonColor(...value) {
    return this.#updateTemplate('likeButtonColor', ...value);
  }

  setTemplateLikeButtonEnabled(...value) {
    return this.#updateTemplate('likeButtonEnabled', ...value);
  }

  setTemplatePayButtonColor(...value) {
    return this.#updateTemplate('payButtonColor', ...value);
  }

  setTemplatePayButtonEnabled(...value) {
    return this.#updateTemplate('payButtonEnabled', ...value);
  }

  setTemplatePayButtonText(...value) {
    return this.#updateTemplate('payButtonText', ...value);
  }

  setTemplatePdfButtonColor(...value) {
    return this.#updateTemplate('pdfButtonColor', ...value);
  }

  setTemplatePdfButtonEnabled(...value) {
    return this.#updateTemplate('pdfButtonEnabled', ...value);
  }

  setTemplatePdfButtonText(...value) {
    return this.#updateTemplate('pdfButtonText', ...value);
  }

  setTemplatePresentationDecorationPriceMode(...value) {
    return this.#updateTemplate('presentationDecorationPriceMode', valueFromEvent(...value));
  }

  setTemplatePresentationAdditionalCostPriceMode(...value) {
    return this.#updateTemplate('presentationAdditionalCostPriceMode', valueFromEvent(...value));
  }

  setTemplateProductDescription(...value) {
    return this.#updateTemplate('productDescription', ...value);
  }

  setTemplateRollupAdditionalCostSellPrice(...value) {
    return this.#updateTemplate('rollupAdditionalCostSellPrice', checkedFromEvent(...value));
  }

  setTemplateRollupDecorationSellPrice(...value) {
    return this.#updateTemplate('rollupDecorationSellPrice', ...value);
  }

  setTemplateShowAdditionalCostItems(...value) {
    return this.#updateTemplate('showAdditionalCostItems', ...value);
  }

  setTemplateShowDeadline(...value) {
    return this.#updateTemplate('showDeadline', ...value);
  }

  setTemplateShowDecorationItems(...value) {
    return this.#updateTemplate('showDecorationItems', ...value);
  }

  setTemplateShowPricing(...value) {
    return this.#updateTemplate('showPricing', ...value);
  }

  setTemplateShowReference(...value) {
    return this.#updateTemplate('showReference', ...value);
  }

  setTemplateShowFooterBlock(...value) {
    return this.#updateTemplate('showFooterBlock', ...value);
  }

  setTemplateShowHeaderBlock(...value) {
    return this.#updateTemplate('showHeaderBlock', ...value);
  }

  setTemplateShowTitleBlock(...value) {
    return this.#updateTemplate('showTitleBlock', ...value);
  }

  setTemplateShowVariants(...value) {
    return this.#updateTemplate('showVariants', ...value);
  }

  setTemplateTax(...value) {
    return this.#updateTemplate('tax', ...value);
  }

  setTemplateVariantPriceMode(...value) {
    return this.#updateTemplate('variantPriceMode', ...value);
  }

  #updateTemplate(field, ...value) {
    value = valueFromEvent(...value);
    if (value !== this.template[field]) {
      return this.copyWith({template: {...this.template, [field]: value}}).notify();
    }
    return this;
  }

  // Helper functions for adding / replacing / updating items -- these are private to SalesDoc and SalesDocItem

  spliceItems(index, deleteCount, ...newItems) {
    // The order of operations is important here, we want to pass the full array
    // to the constructor so that it will set the correct doc in the items
    const items = [...this.items];
    items.splice(index, deleteCount, ...newItems.map((item) => item.copyWith({tax: item.tax ?? this.template.tax ?? this.defaultTax})));
    return this.copyWith({
      items,
      newItemId: newItems?.[0]?.itemId,
      newItem: newItems?.[0],
      deletedItemIndex: deleteCount > 0 && !newItems?.length ? index : -1,
    });
  }

  addItem(item) {
    return this.spliceItems(this.items.length, 0, item);
  }

  addItemAfter(afterItem, item) {
    return this.spliceItems(afterItem ? this.items.indexOf(afterItem) + 1 : this.items.length, 0, item);
  }

  addItemsAfter(afterItem, items) {
    return this.spliceItems(afterItem ? this.items.indexOf(afterItem) + 1 : this.items.length, 0, ...items);
  }

  replaceItem(replaceItem, replaceWith) {
    return this.spliceItems(this.items.indexOf(replaceItem), 1, replaceWith);
  }

  deleteItem(deleteItem) {
    const index = this.items.indexOf(deleteItem);
    return index >= 0 ? this.spliceItems(index, 1) : this;
  }

  updateItems(items, updateWith) {
    if (!Array.isArray(items)) {
      items = [items];
    }

    let doc = this;
    items.forEach((item) => {
      doc = doc.replaceItem(item, item.copyWith(updateWith));
    });

    return doc;
  }

  // Recalculates all the prices, quantities etc. The recalculation does everything.
  recalculate() {
    let items = [...this.items];

    const summary = new SalesDocSummary();
    summary.calculateQuantities(items);

    // Now, recalculate the pricing for non-variants
    items.forEach((item, index) => {
      if (item.type !== SalesDocItem.Type.VARIANT) {
        items[index] = item.recalculatePricing(summary);
      }
    });

    // If there are variants using average pricing, calculate them now
    items.forEach((item, index) => {
      if (item.type === SalesDocItem.Type.VARIANT && item.priceMode === SalesDocItem.PriceMode.AVERAGE) {
        items[index] = item.recalculatePricing(summary, items, 'preAverage');
      }
    });

    // Now, recalculate the pricing for variants - have to do this after the others because they may have rollups
    items.forEach((item, index) => {
      if (item.type === SalesDocItem.Type.VARIANT) {
        items[index] = item.recalculatePricing(summary, items, 'postAverage');
      }
    });

    summary.calculateSummary(items);

    return this.copyWith({items, summary, subTotal: summary.subTotal, taxTotal: summary.taxTotal, total: summary.total});
  }

  syncPresentationColumns() {
    if (this.documentType !== SalesDoc.Type.PRESENTATION) {
      return this;
    }

    let items = this.items;

    // Go through each group, initialize any new rows
    this.getGroupIds().forEach((groupId) => {
      let quantities;

      const rowIds = this.getPresentationRowIdsInGroup(groupId);
      rowIds.forEach((rowId) => {
        const rowItems = this.getVariantItems(rowId);
        if (rowItems.length === 1 && (rowItems[0].quantity === 0 || rowItems[0].isAdditionalCost())) {
          if (quantities == null) {
            if (rowIds.length > 1) {
              // There is already a populated row, grab the quantities from any populate row
              const existingRowId = this.items.find((item) => item.groupId === groupId && item.quantity > 0)?.variantId;
              quantities = existingRowId && items
                .filter((item) => item.variantId === existingRowId)
                .map((item) => item.quantity);
            } else if (rowItems[0].type === SalesDocItem.Type.VARIANT && rowItems[0].product == null) {
              quantities = [50, 100, 500];
            } else if (rowItems[0].type === SalesDocItem.Type.VARIANT
              && (rowItems[0].product?.variants?.length || rowItems[0].product?.defaultPriceBreaks?.length))
            {
              // This is a whole new group, with a product, grab the quantities from the price breaks
              const allPriceBreaks = {};
              rowItems[0].product.defaultPriceBreaks?.forEach((pb) => allPriceBreaks[pb.quantity] = asNumber(pb.quantity));
              rowItems[0].product.variants?.forEach((pv) => {
                if (pv.priceBreaks?.length > 0) {
                  pv.priceBreaks.forEach((pb) => allPriceBreaks[pb.quantity] = asNumber(pb.quantity));
                }
              });
              quantities = [...Object.values(allPriceBreaks)];
              if (quantities[0] === 0) {
                quantities.splice(0, 1);
              }
              if (quantities && quantities.length <= 1 && (quantities[0] === 0 || quantities[0] == null)) {
                // If there is a single price break, or no price breaks, prepopulate with some useful defaults
                quantities = [50, 100, 500];
              }
            } else if (rowItems[0].type === SalesDocItem.Type.DECORATION && rowItems[0].decoration?.priceBreaks > 0) {
              quantities = rowItems[0].decoration.priceBreaks
                .map((pb) => pb.quantity)
                .filter((quantity) => quantity > 0);
              if (quantities && quantities.length <= 1 && (quantities[0] === 0 || quantities[0] == null)) {
                // If there is a single price break, or no price breaks, prepopulate with some useful defaults
                quantities = [50, 100, 500];
              }
            }
          }
          if (quantities && quantities.length > 0) {
            const item = rowItems[0];
            items = items.toSpliced(items.indexOf(item), 1, ...quantities.map((quantity, index) => item.copyWith({
              itemId: index === 0 ? item.itemId : undefined,
              quantity
            })));
          }
        }
      });
    });

    if (items !== this.items) {
      return this.copyWith({items});
    } else {
      return this;
    }
  }

  static makeDecorationSetupCostId(decorationItemId) {
    return `${decorationItemId}:setup`;
  }

  static makeDecorationId(setupCostItemId) {
    if (setupCostItemId.endsWith(':setup')) {
      return setupCostItemId.substring(0, setupCostItemId.length - 6);
    }
    return null;
  }

  static newItemId() {
    return `item:${randomString()}`;
  }

  static newGroupId() {
    return `group:${randomString()}`;
  }

  static newVariantId() {
    return `v:${randomString()}`;
  }
}

export class SalesDocItem {
  static Type = {
    VARIANT: 'variant',
    DECORATION: 'decoration',
    ADDITIONAL_COST: 'additionalCost',

    // Placeholder item types
    GROUP_PLACEHOLDER: 'groupPlaceholder',
    PRODUCT_PLACEHOLDER: 'productPlaceholder',
    DECORATION_PLACEHOLDER: 'decorationPlaceholder',
  };

  static SHIPPING_DESCRIPTION = 'Shipping  ';

  static MarkupTypes = {
    'variant': Markups.MarkupTypes.product,
    'decoration': Markups.MarkupTypes.decoration,
    'additionalCost': Markups.MarkupTypes.additionalCost,
  };

  static LinkedTo = {
    NONE: 'none',
    GROUP: 'group',
    ALL: 'all',
  };

  static PriceMode = {
    VARIANT: 'variant',
    AVERAGE: 'average',
    FLAT_RATE: 'flatRate',
    QUANTITY_BASED: 'quantityBased',
  };

  doc;
  type;
  itemId;
  groupId;
  variantId;

  additionalCost = 0;
  buyPrice = 0;
  buyPricePerUnit;
  buyPriceOverride = false;
  catalog;
  categories;
  code;
  color = null;
  colors;
  decoration;
  decorationId;
  description = null;
  image;
  images;
  isAveragedPrice;
  liked;
  linkedTo;
  markup = 0;
  markupOverride = false;
  name;
  position = null;
  priceMode;
  product;
  productId;
  minQuantity;
  quantity = 0;
  rolledUpCost;
  rolledUpUnitPrice;
  rollupSellPrice = false;
  sellPrice = 0;
  showItem;
  showPricing;
  size = null;
  sizes;
  subTotal;
  tax;
  totalCost;
  unitPrice = 0;
  unitPriceCalc;
  unitPriceOverride = false;
  vendor;
  vendorId;
  vendorName;

  constructor(that) {
    // For the most part, when we are copying another item we want to copy the IDs as well, simply
    // because we do so much copying to maintain immutability, but on occasion we need a new ID, in which
    // case, the object being copied must not have an ID.
    if (that) {
      Object.assign(this, that);
    }
    if (this.type === SalesDocItem.Type.VARIANT && this.linkedTo == null) {
      this.linkedTo = SalesDocItem.LinkedTo.ALL;
    }
    this.itemId = this.itemId ?? SalesDoc.newItemId();
    this.groupId = this.groupId ?? SalesDoc.newGroupId();

    bind(this);
  }

  getAdditionalCostQuantity() {
    if (!this.rollupSellPrice || this.doc.isPresentation()) {
      return this.quantity;
    }

    const decorationItem = this.getDecorationItem();
    if (decorationItem) {
      return decorationItem.quantity;
    }

    if (this.productId) {
      return this.doc.summary.quantities[this.groupId][this.productId] ?? 0;
    }

    return this.doc.summary.getGroupQuantity(this.groupId);
  }

  getDecorationItemId() {
    if (this.type === SalesDocItem.Type.ADDITIONAL_COST) {
      return SalesDoc.makeDecorationId(this.itemId);
    }
    return null;
  }

  getDecorationItem() {
    return this.doc.getItem(this.getDecorationItemId());
  }

  getLinkedQuantity() {
    switch (this.linkedTo) {
      case SalesDocItem.LinkedTo.ALL:
        return this.productId ? this.doc.summary.quantities[this.productId] : this.doc.summary.quantities[this.variantId];

      case SalesDocItem.LinkedTo.GROUP:
        return this.productId ? this.doc.summary.quantities[this.groupId][this.productId] : this.doc.summary.quantities[this.groupId][this.variantId];
    }
    return this.quantity;
  }

  getSetupCost() {
    if (this.type === SalesDocItem.Type.DECORATION) {
      if (this.decoration) {
        return this.decoration.setupPrice;
      } else {
        return this.getSetupCostItem()?.buyPrice ?? 0;
      }
    }
    return 0;
  }

  getSetupCostItem() {
    return this.type === SalesDocItem.Type.DECORATION ? this.doc.getItem(SalesDoc.makeDecorationSetupCostId(this.itemId)) : null;
  }

  // Helper for template substitution
  getSubstitutedDescription() {
    switch (this.type) {
      case SalesDocItem.Type.VARIANT:
        return doTemplateSubstitution(this.description, productSubstitutions, {title: this.product?.title ?? this.name, ...this, ...this.product}, true);

      case SalesDocItem.Type.DECORATION:
        return doTemplateSubstitution(this.description, decorationSubstitutions, {...this, ...this.decoration}, true);

      case SalesDocItem.Type.ADDITIONAL_COST:
        return doTemplateSubstitution(this.description, additionalCostSubstitutions, this, true);
    }

    return this.description;
  }

  hasSetupCostItem() {
    return this.getSetupCostItem() != null;
  }

  isAdditionalCost() {
    return this.type === SalesDocItem.Type.ADDITIONAL_COST;
  }

  isDecoration() {
    return this.type === SalesDocItem.Type.DECORATION || this.type === SalesDocItem.Type.DECORATION_PLACEHOLDER;
  }

  isDecorationSetupCost() {
    return this.getDecorationItemId() != null;
  }

  isPlaceholder() {
    switch (this.type) {
      case SalesDocItem.Type.DECORATION_PLACEHOLDER:
      case SalesDocItem.Type.GROUP_PLACEHOLDER:
      case SalesDocItem.Type.PRODUCT_PLACEHOLDER:
        return true;

      default:
        return false;
    }
  }

  // True for decorations and additional costs in presentations that have a flat rate price
  isPresentationAddOnFlatRate() {
    return this.doc.isPresentation()
      && (this.isDecoration() || this.isAdditionalCost())
      && this.priceMode === SalesDocItem.PriceMode.FLAT_RATE
      && this.variantId != null;
  }

  isProductVariant() {
    return this.type === SalesDocItem.Type.VARIANT || this.type === SalesDocItem.Type.PRODUCT_PLACEHOLDER;
  }

  // Setters and update functions  --- these will update state

  addAdditionalCost({description, productId, vendorId, priceMode, priceBreaks, buyPrice} = {}) {
    // If this is a group placeholder, replace it with an additional cost
    const replaceCount = this.type === SalesDocItem.Type.GROUP_PLACEHOLDER ? 1 : 0;
    const insertPos = this.doc.items.indexOf(this) + 1 - replaceCount;
    return this.doc.spliceItems(insertPos, replaceCount, new SalesDocItem({
      groupId: this.groupId,
      type: SalesDocItem.Type.ADDITIONAL_COST,
      variantId: this.doc.documentType === SalesDoc.Type.PRESENTATION ? SalesDoc.newVariantId() : undefined,
      quantity: 1,
      description: description ?? doTemplateSubstitution(this.doc.template.additionalCostDescription, additionalCostSubstitutions, {}),
      productId: productId ?? null,
      vendor: this.doc.vendors[vendorId] ?? null,
      vendorId: vendorId ?? null,
      vendorName: this.doc.vendors[vendorId]?.name ?? '',
      tax: this.doc.template.tax ?? this.doc.defaultTax,
      rollupSellPrice: this.doc.template.rollupAdditionalCostSellPrice,
      priceMode: priceMode ?? this.doc.template.presentationAdditionalCostPriceMode,
      buyPrice: asCurrencyRateRounded(buyPrice),
      priceBreaks,
      showItem: this.doc.template.showAdditionalCostItems,
    })).syncPresentationColumns().recalculate().notify();
  }

  addDecoration() {
    // If this is a group placeholder, replace it with a decoration placeholder
    const replaceCount = this.type === SalesDocItem.Type.GROUP_PLACEHOLDER ? 1 : 0;
    const insertPos = this.doc.items.indexOf(this) + 1 - replaceCount;
    return this.doc.spliceItems(
      insertPos,
      replaceCount,
      new SalesDocItem({
        groupId: this.groupId,
        type: SalesDocItem.Type.DECORATION_PLACEHOLDER,
        variantId: this.doc.documentType === SalesDoc.Type.PRESENTATION ? SalesDoc.newVariantId() : undefined,
        tax: this.doc.template.tax ?? this.doc.defaultTax,
        rollupSellPrice: this.doc.template.rollupDecorationSellPrice,
        priceMode: this.doc.template.presentationDecorationPriceMode,
        showItem: this.doc.template.showDecorationItems,
      })
    ).recalculate().notify();
  }

  addOneOffDecoration({name, productId, vendorId, priceMode, priceBreaks, buyPrice, setupCost} = {}) {
    // If this is a group placeholder, replace it with a decoration placeholder
    const replaceCount = this.type === SalesDocItem.Type.GROUP_PLACEHOLDER ? 1 : 0;
    const insertPos = this.doc.items.indexOf(this) + 1 - replaceCount;
    const doc = this.doc.spliceItems(insertPos, replaceCount, new SalesDocItem({
      groupId: this.groupId,
      type: SalesDocItem.Type.DECORATION,
      variantId: this.doc.documentType === SalesDoc.Type.PRESENTATION ? SalesDoc.newVariantId() : undefined,
      decoration: null,
      decorationId: null,
      productId: productId ?? null,
      name: name ?? '',
      description: removeMissingSubstitutions(this.doc.template.decorationDescription, oneOffDecorationSubstitutions),
      vendor: this.doc.vendors[vendorId] ?? null,
      vendorId: vendorId ?? null,
      vendorName: this.doc.vendors[vendorId]?.name ?? '',
      tax: this.doc.template.tax ?? this.doc.defaultTax,
      rollupSellPrice: this.doc.template.rollupDecorationSellPrice,
      priceMode: priceMode ?? this.doc.template.presentationDecorationPriceMode,
      buyPrice: asCurrencyRateRounded(buyPrice),
      priceBreaks,
      showItem: this.doc.template.showDecorationItems,
    }));

    if (setupCost) {
      return doc.getItem(doc.newItemId).addSetupCostItem(setupCost);
    }

    return doc.syncPresentationColumns().recalculate().notify();
  }

  addOneOffProduct() {
    // If this is a group placeholder, replace it with a product placeholder
    const replaceCount = this.type === SalesDocItem.Type.GROUP_PLACEHOLDER ? 1 : 0;
    const insertPos = this.doc.items.indexOf(this) + 1 - replaceCount;
    return this.doc.spliceItems(
      insertPos,
      replaceCount,
      new SalesDocItem({
        groupId: this.groupId,
        variantId: SalesDoc.newVariantId(),
        type: SalesDocItem.Type.VARIANT,
        linkedTo: this.doc.documentType === SalesDoc.Type.PRESENTATION ? SalesDocItem.LinkedTo.NONE : null,
        productId: null,
        product: null,
        vendorId: null,
        vendor: null,
        vendorName: '',
        description: removeMissingSubstitutions(this.doc.template.productDescription, oneOffProductSubstitutions),
        tax: this.doc.template.tax ?? this.doc.defaultTax,
        priceMode: this.doc.template.variantPriceMode,
        showItem: this.doc.template.showVariants,
        showPricing: this.doc.template.showPricing,
      }),
    ).syncPresentationColumns().recalculate().notify();
  }

  addProduct({catalog} = {}) {
    // If this is a group placeholder, replace it with a product placeholder
    const replaceCount = this.type === SalesDocItem.Type.GROUP_PLACEHOLDER ? 1 : 0;
    const insertPos = this.doc.items.indexOf(this) + 1 - replaceCount;
    return this.doc.spliceItems(
      insertPos,
      replaceCount,
      new SalesDocItem(SalesDocItem.initVariantProps({salesDoc: this.doc, groupId: this.groupId, catalog})),
    ).recalculate().notify();
  }

  addProductVariant() {
    return this.doc.addItemAfter(this, new SalesDocItem({
      groupId: this.groupId,
      variantId: this.variantId,
      type: this.type,
      linkedTo: this.linkedTo,
      tax: this.tax,
      priceMode: this.priceMode,
      showItem: this.showItem,
      showPricing: this.showPricing,
      product: this.product,
      productId: this.productId,
      code: this.code,
      description: this.description,
      image: this.image,
      name: this.name,
      quantity: 0,
      vendor: this.vendor,
      vendorId: this.vendorId,
      vendorName: this.vendorName,
    }))
      .recalculate()
      .notify();
  }

  addSetupCostItem(...setupPrice) {
    if (this.type === SalesDocItem.Type.DECORATION) {
      setupPrice = asCurrencyRateRounded(this.decoration?.setupPrice ?? valueFromEvent(...setupPrice));
      const setupCostItem = this.getSetupCostItem();
      if (setupCostItem) {
        return setupCostItem.setBuyPrice(setupPrice);
      } else {
        const updateItems = this.variantId != null ? this.doc.getVariantItems(this.variantId) : [this];
        const isPresentation = this.doc.isPresentation();
        const newVariantId = isPresentation ? SalesDoc.newVariantId() : undefined;
        const newItems = [...this.doc.items];
        updateItems.forEach((updateItem) => {
          const index = newItems.indexOf(updateItem);
          newItems.splice(index + 1, 0, new SalesDocItem({
            type: SalesDocItem.Type.ADDITIONAL_COST,
            variantId: newVariantId,
            itemId: SalesDoc.makeDecorationSetupCostId(updateItem.itemId),
            groupId: updateItem.groupId,
            decorationId: updateItem.decorationId,
            productId: updateItem.productId,
            vendorId: updateItem.vendorId,
            vendor: updateItem.vendor,
            vendorName: updateItem.vendorName,
            name: `${updateItem.name} - Setup`,
            description: `${updateItem.name} - Setup`,
            buyPrice: asCurrencyRateRounded(setupPrice),
            buyPriceOverride: false,
            additionalCost: 0,
            markup: null,
            quantity: isPresentation ? updateItem.quantity : 1,
            tax: updateItem.tax,
            rollupSellPrice: updateItem.rollupSellPrice,
            priceMode: isPresentation ? updateItem.doc.template.presentationAdditionalCostPriceMode : undefined,
            showItem: updateItem.showItem,
          }));
        });
        return this.doc.copyWith({items: newItems}).syncPresentationColumns().recalculate().notify();
      }
    }
    return this.doc;
  }

  copy() {
    let addAfter = this;
    const newItems = [this.copyWith({itemId: undefined})];
    if (this.isDecoration()) {
      const setupCostItem = this.getSetupCostItem();
      if (setupCostItem) {
        addAfter = setupCostItem;
        newItems.push(setupCostItem.copyWith({itemId: SalesDoc.makeDecorationSetupCostId(newItems[0].itemId)}));
      }
    }
    return this.doc.addItemsAfter(addAfter, newItems).clearMarkupsOnVariants(this.variantId).recalculate().notify();
  }

  copyWith(that) {
    return new SalesDocItem({...this, ...that});
  }

  delete() {
    const setupCostItem = this.getSetupCostItem();
    return this.doc.deleteItem(this).deleteItem(setupCostItem).clearMarkupsOnVariants(this.variantId).syncPresentationColumns().recalculate().notify();
  }

  deleteSetupCostItem() {
    if (this.type === SalesDocItem.Type.DECORATION) {
      const setupCostItem = this.getSetupCostItem();
      if (setupCostItem) {
        if (this.doc.isPresentation() && setupCostItem.variantId) {
          return this.doc.deleteVariants(setupCostItem.variantId);
        } else {
          return setupCostItem.delete();
        }
      }
    }
    return this.doc;
  }

  clearBuyPriceOverride() {
    const items = this.isPresentationAddOnFlatRate() ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {buyPrice: null, buyPriceOverride: false}).recalculate().notify();
  }

  clearMarkupOverride() {
    const items = this.isPresentationAddOnFlatRate() ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {markup: null, markupOverride: false}).recalculate().notify();
  }

  clearUnitPriceOverride() {
    return this.setUnitPrice(null);
  }

  setAdditionalCost(...value) {
    const items = this.isPresentationAddOnFlatRate() ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {additionalCost: valueFromEvent(...value)}).recalculate().notify();
  }

  setBuyPrice(...value) {
    value = valueFromEvent(...value);
    const items = this.isPresentationAddOnFlatRate() ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {
      buyPrice: value,
      buyPriceOverride: value != null && (this.type !== SalesDocItem.Type.ADDITIONAL_COST || this.decoration != null || this.productId != null),
      markup: this.markupOverride ? this.markup : null,
    }).recalculate().notify();
  }

  setCategories(categories) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {categories: (categories ?? []).toSorted(byLocaleCaseInsensitive)}).notify();
  }

  setCode(...value) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {code: valueFromEvent(...value)}).notify();
  }

  setColor(...value) {
    return this.copyWith({color: valueFromEvent(...value)}).#replace(this).recalculate().notify();
  }

  setColors(colors) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {colors: (colors ?? []).toSorted(byLocaleCaseInsensitive)}).recalculate().notify();
  }

  setDecoration(decoration) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    if (this.doc.decorations[decoration._id]) {
      decoration = this.doc.decorations[decoration._id];
    }
    let doc = this.doc.updateItems(items, {
      groupId: this.groupId,
      type: SalesDocItem.Type.DECORATION,
      decoration: decoration,
      decorationId: decoration._id,
      productId: decoration.productId,
      name: decoration.name,
      description: doTemplateSubstitution(this.doc.template.decorationDescription, decorationSubstitutions, decoration),
      vendor: decoration.vendor,
      vendorId: decoration.vendorId,
      vendorName: decoration.vendorName,
    })
      .syncPresentationColumns()
      .addDecoration(decoration); // This will recalc and notify

    if (decoration.setupPrice > 0) {
      doc = doc.getItem(this.itemId).addSetupCostItem(decoration.setupPrice); // This will also recalc and notify
    } else {
      doc = doc.getItem(this.itemId).deleteSetupCostItem(); // This will also recalc and notify
    }

    return doc;
  }

  setDescription(...value) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {description: valueFromEvent(...value)}).recalculate().notify();
  }

  setImage(...value) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {image: valueFromEvent(...value)}).recalculate().notify();
  }

  setImages(images) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {images}).recalculate().notify();
  }

  setMarkup(...value) {
    value = valueFromEvent(...value);
    const items = this.isPresentationAddOnFlatRate() ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {
      markup: value,
      markupOverride: value != null && value !== '',
    })
      .recalculate()
      .notify();
  }

  setMargin(...value) {
    return this.setMarkup(markupFromMargin(valueFromEvent(...value)));
  }

  setName(...value) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {name: valueFromEvent(...value)}).notify();
  }

  setPosition(...value) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {position: valueFromEvent(...value)}).notify();
  }

  setPriceMode(...value) {
    value = valueFromEvent(...value);
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    if (this.doc.isPresentation()
        && (this.isDecoration() || this.isAdditionalCost())
        && this.variantId != null) {
      // For an addon in a presentation we have to update all the columns, whether we are applying the same value to all,
      // or resetting to the defaults
      if (value === SalesDocItem.PriceMode.FLAT_RATE) {
        return this.doc.updateItems(items, {
          priceMode: value,
          additionalCost: this.additionalCost,
          buyPrice: this.buyPrice,
          buyPriceOverride: this.buyPriceOverride,
          markup: this.markup,
          markupOverride: this.markupOverride,
          unitPrice: this.unitPrice,
          unitPriceOverride: this.unitPriceOverride,
          unitPriceLocked: this.unitPriceLocked,
        }).recalculate().notify();
      } else {
        // When switching to quantity based pricing, we change the price mode in the first item,
        // and reset the price values to defaults in the other items.
        items.splice(items.indexOf(this), 1);
        return this.copyWith({priceMode: value}).#replace(this)
          .updateItems(items, {
            priceMode: value,
            additionalCost: 0,
            buyPrice: null,
            buyPriceOverride: false,
            markup: null,
            markupOverride: false,
            unitPrice: null,
            unitPriceOverride: false,
            unitPriceLocked: false,
          })
          .recalculate()
          .notify();
      }
    } else {
      return this.doc.updateItems(items, {priceMode: value}).recalculate().notify();
    }
  }

  setProduct(product) {
    const items = this.variantId != null && this.productId ? this.doc.getVariantItems(this.variantId) : this;
    if (this.doc.products[product._id]) {
      product = this.doc.products[product._id];
    }
    return this.doc.updateItems(items, SalesDocItem.initVariantPropsFromProduct({salesDoc: this.doc, product}))
      .syncPresentationColumns()
      .addProduct(product); // No need for recalc or notify, addProduct will do it
  }

  setQuantity(...value) {
    value = valueFromEvent(...value);
    if (value !== this.quantity) {
      if (this.doc.isPresentation()) {
        return this.doc.setPresentationGroupColumnQuantity(this, value);
      } else {
        return this.copyWith({quantity: value}).#replace(this).clearMarkupsOnVariants(this.variantId).recalculate().notify();
      }
    } else {
      return this;
    }
  }

  setRollupSellPrice(value) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {
      rollupSellPrice: checkedFromEvent(value),
      ...(this.type === SalesDocItem.Type.ADDITIONAL_COST ? {unitPriceOverride: false} : {}),
    }).recalculate().notify();
  }

  setSetupCost(value) {
    return this.addSetupCostItem(value);
  }

  setSize(...value) {
    return this.copyWith({size: valueFromEvent(...value)}).#replace(this).recalculate().notify();
  }

  setSizes(sizes) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {sizes: sortSizes(sizes ?? [])}).recalculate().notify();
  }

  setShowItem(value) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {showItem: checkedFromEvent(value)}).recalculate().notify();
  }

  setShowPricing(value) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {showPricing: checkedFromEvent(value)}).recalculate().notify();
  }

  setTax(...tax) {
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {tax: valueFromEvent(...tax)}).recalculate().notify();
  }

  setUnitPrice(...value) {
    value = valueFromEvent(...value);
    const items = (this.type === SalesDocItem.Type.VARIANT && this.priceMode === SalesDocItem.PriceMode.AVERAGE) || this.isPresentationAddOnFlatRate() ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {unitPrice: value, unitPriceOverride: value != null}).recalculate().notify();
  }

  setVendor(vendor) {
    if (this.doc.vendors[vendor._id] && this.doc.vendors[vendor._id].name) {
      vendor = this.doc.vendors[vendor._id];
    }
    const items = this.variantId != null ? this.doc.getVariantItems(this.variantId) : this;
    return this.doc.updateItems(items, {
      vendor: vendor,
      vendorId: vendor._id,
      vendorName: vendor.name
    })
      .addVendor(vendor);
  }

  // Helpers for populating

  static initVariantProps({salesDoc, type, groupId, variantId, catalog}) {
    return {
      groupId,
      variantId: variantId ?? SalesDoc.newVariantId(),
      type: type ?? SalesDocItem.Type.PRODUCT_PLACEHOLDER,
      linkedTo: salesDoc.documentType === SalesDoc.Type.PRESENTATION ? SalesDocItem.LinkedTo.NONE : null,
      tax: salesDoc.template.tax ?? salesDoc.defaultTax,
      priceMode: salesDoc.template.variantPriceMode,
      showItem: salesDoc.template.showVariants,
      showPricing: salesDoc.template.showPricing,
      ...(catalog ? {catalog} : {}),
    };
  }

  static initVariantPropsFromProduct({salesDoc, product}) {
    return {
      type: SalesDocItem.Type.VARIANT,
      product: product,
      productId: product._id,
      code: product.code,
      ...(!salesDoc.isPresentation() ? {} : {
        colors: product.colors?.length > 0 ? [...product.colors] : undefined,
        sizes: product.sizes?.length > 0 ? [...product.sizes] : undefined,
        images: product.namedImages && Object.values(product.namedImages).length > 0
          ? uniq([product.primaryImage, ...Object.values(product.namedImages)]).filter(Boolean)
          : undefined,
      }),
      description: doTemplateSubstitution(salesDoc.template.productDescription, productSubstitutions, product),
      image: product.primaryImage ?? product.namedImages?.[0],
      name: product.title ?? '',
      vendor: product.vendor,
      vendorId: product.vendorId,
      vendorName: product.vendorName,
    };
  }

  // Helpers for updating

  #replace(replace) {
    return this.doc.replaceItem(replace, this);
  }

  // Calculation functions, for simplicity, each item is copied and then the recalculation functions
  // mutate the item itself.

  recalculatePricing(...args) {
    // Make a copy of the item, it's easier for the calculation functions if they can mutate the item.
    const item = new SalesDocItem(this);
    switch (item.type) {
      case SalesDocItem.Type.VARIANT:
        return item.#recalculateVariantPricing(...args);

      case SalesDocItem.Type.DECORATION:
        return item.#recalculateDecorationPricing(...args);

      case SalesDocItem.Type.ADDITIONAL_COST:
        return item.#recalculateAdditionalCostPricing(...args);

      default:
        item.buyPrice = item.buyPrice ?? 0;
        item.totalCost = item.totalCost ?? 0;
        item.unitPrice = item.unitPrice ?? 0;
        item.subTotal = item.subTotal ?? 0;
        return item;
    }
  }

  #recalculateVariantPricing(summary, items, phase) {
    if (this.priceMode !== SalesDocItem.PriceMode.AVERAGE || phase === 'preAverage') {
      // Determine the quantity for price break purposes. By default, we use the total number of the product
      // across the entire sales doc, but it can also be linked to the group or to nothing.
      let linkedQuantity;
      if (this.linkedTo === SalesDocItem.LinkedTo.GROUP) {
        linkedQuantity = summary.quantities[this.groupId][this.productId];
      } else if (!this.productId || this.linkedTo === SalesDocItem.LinkedTo.NONE) {
        linkedQuantity = this.quantity;
      } else {
        linkedQuantity = summary.quantities[this.productId];
      }

      // Determine the default buy price. It's from the correct break, or an average of all matching price breaks.
      let defaultBuyPrice;
      if (this.product) {
        const variants = this.doc.isPresentation()
          ? this.product.variants?.filter((v) => (!this.colors?.length || this.colors.includes(v.color)) && (!this.sizes?.length || this.sizes.includes(v.size)))
          : this.product.variants?.filter((v) => (this.color == null || this.color === v.color) && (this.size == null || this.size === v.size));
        const calc = variants.filter(Boolean).reduce((acc, v) => {
          const pbs = v.priceBreaks ?? this.product.defaultPriceBreaks;
          if (pbs) {
            const price = pbs.findLast((pb) => linkedQuantity >= pb.quantity)?.price ?? pbs[0]?.price ?? 0;
            return {
              minQuantity: acc.minQuantity == null ? pbs[0].quantity : Math.min(acc.minQuantity, pbs[0].quantity),
              price: acc.price + price,
              minPrice: acc.minPrice == null ? price : Math.min(acc.minPrice, price),
              maxPrice: acc.maxPrice == null ? price : Math.max(acc.maxPrice, price),
              count: acc.count + 1,
            };
          }
          return acc;
        }, {minQuantity: null, price: 0, count: 0, minPrice: null, maxPrice: null});
        if (calc.count > 0) {
          defaultBuyPrice = asRounded(calc.price / calc.count, this.doc.getRounding());
          this.minQuantity = calc.minQuantity;
          this.isAveragedPrice = calc.minPrice !== calc.maxPrice;
        } else if (this.product.defaultPriceBreaks) {
          defaultBuyPrice = this.product.defaultPriceBreaks.findLast((pb) => linkedQuantity >= pb.quantity)?.price ?? this.product.defaultPriceBreaks[0]?.price ?? 0;
          this.minQuantity = this.product.defaultPriceBreaks[0]?.quantity;
          this.isAveragedPrice = false;
        }
      }
      this.#updateBuySellPrice(defaultBuyPrice, linkedQuantity);
    }

    if (this.priceMode !== SalesDocItem.PriceMode.AVERAGE || phase === 'postAverage') {
      let sellPrice = this.sellPrice;

      if (this.priceMode === SalesDocItem.PriceMode.AVERAGE) {
        // Calculate the average sellPrice
        const sameItems = items.filter((item) => item.priceMode === SalesDocItem.PriceMode.AVERAGE && item.groupId === this.groupId && item.productId === this.productId);
        let count = 0;
        let price = 0;
        sameItems.forEach((item) => {
          count += item.quantity;
          price += item.quantity * item.sellPrice;
        });
        sellPrice = asRounded(price / (count || 1), this.doc.getRounding());
      }

      // Now calculate the unit price based on the sell price, plus any decorations and additional costs that are rolled up into it
      const [rolledUpCost, rolledUpUnitPrice] = items
        .filter((that) => that.groupId === this.groupId && (that.isDecoration() || that.isAdditionalCost()))
        .reduce((rollups, that) => {
          // Check if the price of that item should be rolled up into this item
          let rollup = that.rollupSellPrice;
          if (rollup) {
            if (this.doc.documentType === SalesDoc.Type.PRESENTATION && that.quantity !== this.quantity) {
              rollup = false;
            } else if (that.isDecoration() || that.isDecorationSetupCost()) {
              if (that.productId != null && that.productId !== this.productId) {
                rollup = false;
              }
            }
          }
          if (rollup) {
            rollups[0] += (that.buyPricePerUnit ?? that.buyPrice) + that.additionalCost;
            rollups[1] += that.unitPrice;
          }
          return rollups;
        }, [0, 0]);

      this.rolledUpCost = rolledUpCost;
      this.rolledUpUnitPrice = rolledUpUnitPrice;

      this.#updateUnitPriceAndSubTotal(sellPrice + rolledUpUnitPrice);
    }

    return this;
  }

  #recalculateDecorationPricing(summary) {
    // Decorations don't have their own quantity - it comes from the group or product, unless there is no variant, or it's a presentation
    if (this.doc.documentType !== SalesDoc.Type.PRESENTATION) {
      if (this.productId) {
        this.quantity = summary.quantities[this.groupId][this.productId] ?? 0;
      } else if (summary.quantities[this.groupId].quantity > 0) {
        this.quantity = summary.quantities[this.groupId].quantity;
      } else {
        this.rollupSellPrice = false;
        if (this.quantity == null) {
          this.quantity = 1;
        }
      }
    }

    // Determine the default price from the price breaks
    let defaultBuyPrice;
    const priceBreaks = this.priceBreaks ?? this.decoration?.priceBreaks;
    if (priceBreaks) {
      defaultBuyPrice = asCurrencyRateRounded(priceBreaks.findLast((pb) => this.quantity >= pb.quantity)?.price ?? priceBreaks[0]?.price ?? 0);
    }

    this.#updateBuySellPrice(defaultBuyPrice, this.quantity);

    this.#updateUnitPriceAndSubTotal(this.sellPrice);

    return this;
  }

  #recalculateAdditionalCostPricing(summary) {
    if (!this.doc.groupHasProductVariants(this.groupId)) {
      this.rollupSellPrice = false;
    }

    // For additional costs the unit price depends on whether the price is rolled up. If it is not rolled up
    // the quantity is 1 and the unit price is the price; If it is rolled up, the quantity is the quantity
    // of all the variants in the group (or the quantity of the linked/unlinked products if it is a decoration
    // additional cost), and the unit price is divided by that; If it is in a presentation, the quantity of
    // the column is used.
    let quantity;
    if (!this.rollupSellPrice) {
      quantity = 1;
    } else if (this.doc.isPresentation()) {
      quantity = this.quantity;
    } else if (this.productId) {
      quantity = summary.quantities[this.groupId][this.productId] ?? 0;
    } else if (this.isDecorationSetupCost()) {
      quantity = summary.quantities[this.groupId].unlinked;
    } else {
      quantity = summary.getGroupQuantity(this.groupId);
    }
    if (quantity < 1 || quantity == null) {
      quantity = 1;
    }

    // Determine the default price from the decoration setup cost or the price breaks
    let defaultBuyPrice;
    if (this.decoration) {
      defaultBuyPrice = asCurrencyRateRounded(this.decoration.setupPrice);
    } else if (this.priceBreaks) {
      defaultBuyPrice = asCurrencyRateRounded(this.priceBreaks.findLast((pb) => quantity >= pb.quantity)?.price ?? 0);
    }

    this.#updateBuySellPrice(defaultBuyPrice, 1);
    this.totalCost = this.buyPrice;

    this.buyPricePerUnit = asCurrencyRateRounded(this.buyPrice / quantity);
    const unitPrice = asRounded(this.sellPrice / quantity, this.doc.getRounding());

    this.#updateUnitPriceAndSubTotal(unitPrice, quantity);

    return this;
  }

  #updateBuySellPrice(defaultBuyPrice, effectiveQuantity) {
    // When converting from an old quote, it is unknown whether the buy price has been overridden, so we
    // determine that now. Basically, if the buyPrice is different to the price break, it's been overridden.
    if (this.buyPriceOverride == null) {
      this.buyPriceOverride = defaultBuyPrice != null && asCurrencyRateRounded(defaultBuyPrice) !== asCurrencyRateRounded(this.buyPrice);
    }

    // Store the new buyPrice, unless it is overridden
    if ((!this.buyPriceOverride && defaultBuyPrice) || this.buyPrice == null) {
      if (!this.markupOverride && asCurrencyRateString(defaultBuyPrice) !== asCurrencyRateString(this.buyPrice)) {
        this.markup = null;
      }
      this.buyPrice = defaultBuyPrice ?? 0;
    }
    this.totalCost = asCurrencyRounded((this.buyPrice + this.additionalCost) * this.quantity);

    // Find the correct default markup
    if (this.markup == null) {
      const markup = this.doc.markups?.findMarkupValue({
        type: SalesDocItem.MarkupTypes[this.type],
        vendorId: this.vendorId,
        brand: this.product?.brand ?? null,
        category: this.product?.category ?? this.decoration?.category ?? null,
        subCategory: this.product?.subCategory ?? null,
        quantity: effectiveQuantity,
        price: this.buyPrice,
      });
      if (markup != null) {
        this.markup = markup;
      } else {
        this.markup = 0;
      }
    }

    // Calculate the new sell price: basically, buyPrice + additionalCost with the markup on top
    const sellPrice = asRounded((asNumber(this.buyPrice) + asNumber(this.additionalCost)) * (1 + asNumber(this.markup) / 100.0), this.doc.getRounding());
    if (sellPrice !== this.sellPrice) {
      this.sellPrice = sellPrice;
      if (asRoundedString(sellPrice, this.doc.getRounding()) !== asRoundedString(this.sellPrice, this.doc.getRounding())) {
        this.unitPriceOverride = false;
      }
    }
  }

  #updateUnitPriceAndSubTotal(unitPrice, effectiveQuantity) {
    unitPrice = asRounded(unitPrice, this.doc.getRounding());

    if (this.unitPriceCalc == null) {
      // If the calculated unit price has not been set, we will store it now (it's not part of the model)
      this.unitPriceCalc = unitPrice;
      if (this.unitPriceOverride == null) {
        this.unitPriceOverride = asRoundedString(unitPrice, this.doc.getRounding()) !== asRoundedString(this.unitPrice, this.doc.getRounding());
      }
    } else if (unitPrice !== this.unitPriceCalc) {
      // If the calculated unit price has changed, we will store it, and we disable the unit price override
      this.unitPriceOverride = false;
      this.unitPriceCalc = unitPrice;
    }

    // If the unit price is not being overridden, or is unset, we will use the new value
    if (!this.unitPriceOverride || this.unitPrice == null) {
      this.unitPrice = unitPrice;
    }
    this.subTotal = asCurrencyRounded(this.unitPrice * (effectiveQuantity ?? this.quantity));
  }
}

export class SalesDocSummary {
  quantities = {};
  summary = {};
  subTotal;
  taxTotal;
  total;

  constructor() {
  }

  getDocumentSummary() {
    return this.summary;
  }

  getGroupQuantity(groupId) {
    return this.quantities[groupId].quantity;
  }

  getGroupSummary(groupId) {
    return this.summary[groupId];
  }

  getItemSummary(itemId) {
    return this.summary[itemId];
  }

  getVariantQuantity(variantId) {
    return this.quantities[variantId];
  }

  getVariantRevenue(variantId) {
    return this.summary[variantId].totalRevenue;
  }

  getVariantSummary(variantId) {
    return {
      hasRollup: this.summary[variantId].rolledUpRevenue > 0 || this.summary[variantId].rolledUpCost > 0,
      totalCost: this.summary[variantId].totalCost,
      totalRevenue: this.summary[variantId].totalRevenue - this.summary[variantId].rolledUpRevenue,
      rolledUpCost: this.summary[variantId].totalCost + this.summary[variantId].rolledUpCost,
      rolledUpRevenue: this.summary[variantId].totalRevenue,
      quantity: this.summary[variantId].quantity,
    };
  }

  calculateQuantities(items) {
    // Initialize the quantities to 0.
    this.quantities = items
      .filter((item) => item.type === SalesDocItem.Type.VARIANT || !item.doc.groupHasProductVariants(item.groupId))
      .reduce((q, item) => ({
        ...q,
        [item.variantId]: 0,
        [item.productId]: 0,
        [item.groupId]: {
          ...q[item.groupId],
          quantity: 0,
          unlinked: 0,
          [item.productId]: 0,
        }
      }), {quantity: 0, unlinked: 0});

    // Now do the additions
    items
      .filter((item) => item.isProductVariant() || item.variantId != null)
      .forEach((item) => {
        const quantity = asNumber(item.quantity);
        if (item.isProductVariant()) {
          this.quantities.quantity += quantity;
          this.quantities[item.groupId].quantity += quantity;
          this.quantities[item.productId] += quantity;
          this.quantities[item.groupId][item.productId] += quantity;
          if (!item.product || item.product.decorations == null || item.product.decorations.length === 0) {
            this.quantities.unlinked += quantity;
            this.quantities[item.groupId].unlinked += quantity;
          }
        }
        if (item.variantId) {
          this.quantities[item.variantId] += quantity;
        }
      });
  }

  calculateSummary(items) {
    this.summary = {
      totalCost: 0,
      totalRevenue: 0,
      totalProductCost: 0,
      totalDecorationCost: 0,
      totalAdditionalCost: 0,
      quantity: this.quantities.quantity,
    };

    items.forEach((item) => {
      // Document summary
      this.summary.totalCost += item.totalCost;
      if (!item.rollupSellPrice) {
        this.summary.totalRevenue += item.subTotal;
      }
      if (item.isProductVariant()) {
        this.summary.totalProductCost += item.totalCost;
      } else if (item.isDecoration() || item.isDecorationSetupCost()) {
        this.summary.totalDecorationCost += item.totalCost;
      } else {
        this.summary.totalAdditionalCost += item.totalCost;
      }

      // Group summary
      if (!this.summary[item.groupId]) {
        this.summary[item.groupId] = {
          totalCost: 0,
          totalRevenue: 0,
          quantity: this.quantities[item.groupId].quantity,
        };
      }
      this.summary[item.groupId].totalCost += item.totalCost;
      if (!item.rollupSellPrice) {
        this.summary[item.groupId].totalRevenue += item.subTotal;
      }

      // Variant and item summary
      const variantOrItemId = item.variantId ?? item.itemId;
      if (!this.summary[variantOrItemId]) {
        this.summary[variantOrItemId] = {
          totalCost: 0,
          totalRevenue: 0,
          rolledUpCost: 0,
          rolledUpRevenue: 0,
          quantity: 0,
        };
      }
      this.summary[variantOrItemId].totalCost += item.totalCost;
      this.summary[variantOrItemId].totalRevenue += item.subTotal;
      this.summary[variantOrItemId].rolledUpCost += (item.rolledUpCost ?? 0) * item.quantity;
      this.summary[variantOrItemId].rolledUpRevenue += (item.rolledUpUnitPrice ?? 0) * item.quantity;
      this.summary[variantOrItemId].quantity += item.quantity;
    });

    this.subTotal = this.summary.totalRevenue;
    this.taxTotal = getTaxTotal(items.filter((item) => item.tax && !item.rollupSellPrice));
    this.total = this.taxTotal + this.subTotal;
  }
}

import React, {useCallback, useMemo, useState} from 'react';
import CheckedIcon from '@mui/icons-material/CheckBox';
import UncheckedIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  AutoCompleteContainer,
  AutoCompleteList, AutoCompleteListItem,
  AutoCompletePopover,
  AutoCompleteValueContainer,
} from '../Popovers';
import {flattenChildren, HoopsPropTypes} from '../utils';
import classNames from 'classnames';
import {ChipList} from '../Chips/ChipList';
import {registerGlobalStyle} from '../../theme';
import {BodyText} from '../Text';
import {TextInput} from '../Basic';
import {Row} from '../Layout';

registerGlobalStyle('.multi-select', (theme) => ({
  '.multi-select-value:has(>.chip-list)': {paddingBlock: theme.spacing(.5, .625)},
  '.chip-list': {width: '100%'},
}));

registerGlobalStyle('.multi-select-popover', (theme) => ({
  '.multi-select-item': {
    display: 'flex',
    alignItems: 'end',
    gap: theme.spacing(1),
    svg: {color: theme.colors.text.mediumIcon,},
    '&.checked svg': {color: theme.colors.text.highlight,}
  },
  '.search-bar .text-input': {width: '100%'},
}));

export function MultiSelect({className, emptyList, filter, initialOpen, label, loading, placeholder, placement, value, onChange, onClose, children}) {
  const [search, setSearch] = useState('');

  const handleSearchChange = useCallback((e) => {
    setSearch(e.target.value);
  }, []);

  const handleSelect = useCallback((select) => {
    if (onChange) {
      onChange([...(value ?? []), select]);
    }
  }, [onChange, value]);

  const handleListItemClick = useCallback((e) => {
    const svg = e.target.closest('svg');
    if (svg) {
      e.preventDefault();
      const item = e.target.closest('li')?.getAttribute('value');
      const index = value?.indexOf(item);
      if (index >= 0) {
        onChange(value.toSpliced(index, 1));
      } else {
        onChange([...(value ?? []), item]);
      }
    }
  }, [onChange, value]);

  const handleAbsorbClick = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const handleClear = useCallback(() => {
    setSearch('');
  }, []);

  const filteredChildren = useMemo(() => {
    if (!search) {
      return children;
    }
    const regex = new RegExp(search, 'i');
    return flattenChildren(children).filter((child) => regex.test(child.props.text));
  }, [children, search]);

  return (
    <AutoCompleteContainer
      className={[className, 'multi-select']}
      label={label}
      loading={loading}
      placeholder={placeholder}
      onChange={handleSelect}
      onClose={onClose}
      onListItemClick={handleListItemClick}
    >
      <AutoCompleteValueContainer className={classNames([className, 'input-outline multi-select-value'])} initialOpen={initialOpen} showOpenArrow>
        {!!value?.length &&
          <ChipList selected={value} onChangeSelected={onChange} onMouseDown={handleAbsorbClick} unsorted/>
        }
      </AutoCompleteValueContainer>
      <AutoCompletePopover className={'multi-select-popover'} placement={placement}>
        {filter &&
          <Row className={'search-bar'}>
            <TextInput value={search} onChange={handleSearchChange} placeholder={'Type to filter results'} clearable onClear={handleClear}/>
          </Row>
        }
        <AutoCompleteList emptyList={emptyList}>
          {filteredChildren}
        </AutoCompleteList>
      </AutoCompletePopover>
    </AutoCompleteContainer>
  );
}

MultiSelect.propTypes = {
  className: HoopsPropTypes.className,
  emptyList: HoopsPropTypes.string,
  filter: HoopsPropTypes.bool,
  initialOpen: HoopsPropTypes.bool,
  label: HoopsPropTypes.string,
  loading: HoopsPropTypes.bool,
  placeholder: HoopsPropTypes.string,
  placement: HoopsPropTypes.string,
  value: HoopsPropTypes.arrayOfString,
  onChange: HoopsPropTypes.func,
  onClose: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};

export function MultiSelectItem({className, checked, text, value, children}) {
  return (
    <AutoCompleteListItem className={[className, 'multi-select-item', checked && 'checked']} value={value}>
      {checked === true &&
        <CheckedIcon/>
      }
      {checked === false &&
        <UncheckedIcon/>
      }
      {text ? <BodyText>{text}</BodyText> : children}
    </AutoCompleteListItem>
  );
}

MultiSelectItem.propTypes = {
  className: HoopsPropTypes.className,
  checked: HoopsPropTypes.bool,
  text: HoopsPropTypes.string,
  value: HoopsPropTypes.string,
  children: HoopsPropTypes.children,
};

import React, {useCallback} from 'react';
import {Favorite as LikeIcon} from '@mui/icons-material';
import {registerGlobalStyle} from '../../../theme';
import {HoopsPropTypes} from '../../../componentsLib/utils';
import {Button} from '../../../componentsLib/Basic/Button';
import {ButtonTheme} from '../../../componentsLib/Basic/ButtonTheme';
import {ToolTip} from '../../../componentsLib/Basic/ToolTip';
import {useAnonLikeGroupSalesDoc, useAnonUnlikeGroupSalesDoc} from '../../../hooks/api';
import {usePageContext} from '../../../componentsLib/Layout';

registerGlobalStyle('.like-button', (theme) => ({
  '&.button.icon': {padding: theme.spacing(0.85, 1)},
  '&.read-only': {pointerEvents: 'none'},
  '.busy-spinner': {
    position: 'absolute',
    top: theme.spacing(2.2),
    marginRight: 0,
    opacity: .3,
    'span, svg': {
      width: 32,
      height: 32
    }
  },
}));

const likeTip = 'We’ll be notified when \n you like products.';
const unlikeTip = 'Remove Like';

export const LikeButton = ({className, readOnly, groupId}) => {
  const {salesDoc, updateCache} = usePageContext();
  const {like: likeGroupSalesDocAPI, isInProgress: loadingLike} = useAnonLikeGroupSalesDoc();
  const {unlike: unlikeGroupSalesDocAPI, isInProgress: loadingUnlike} = useAnonUnlikeGroupSalesDoc();
  const likeLoading = (loadingLike || loadingUnlike);

  const firstItem = salesDoc.getMainItemInGroup(groupId);
  const liked = firstItem.liked;

  const likeGroup = useCallback(async (e) => {
    e.stopPropagation();
    const comment = `${salesDoc.customer.name} ${liked ? 'UNLIKED' : 'LIKED'} ${firstItem.name}`;

    const res = await (liked ? unlikeGroupSalesDocAPI : likeGroupSalesDocAPI)(
      {id: salesDoc?._id, groupId: groupId, comment},
      {
        successMessage: false,
        errorMessage: ({message}) => message,
      }
    );
    if (res) {
      updateCache(res);
    }
  }, [firstItem, salesDoc, likeGroupSalesDocAPI, unlikeGroupSalesDocAPI, updateCache, groupId, liked]);

  return (
    <ToolTip top disabled={readOnly} tip={<span style={{whiteSpace: 'pre-line'}}>{liked ? unlikeTip : likeTip}</span>}>
      <ButtonTheme color={salesDoc.template.likeButtonColor}>
        <Button className={['like-button', readOnly && 'read-only', className]} navStandard={!liked} navPrimary={liked} prefix={LikeIcon} loading={likeLoading} onClick={likeGroup} />
      </ButtonTheme>
    </ToolTip>
  );
};

LikeButton.propTypes = {
  className: HoopsPropTypes.className,
  readOnly: HoopsPropTypes.bool,
  groupId: HoopsPropTypes.string
};

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import AddGroupIcon from '@mui/icons-material/LibraryAddOutlined';
import AdditionalCostIcon from '@mui/icons-material/PlaylistAdd';
import CatalogIcon from '@mui/icons-material/Style';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertTextIcon from '@mui/icons-material/ReadMore';
import DecorationIcon from '@mui/icons-material/AutoFixHigh';
import {ReactComponent as OneOffDecorationIcon} from '../../../assets/images/svgicons/colors.svg';
import {ReactComponent as ShippingIcon} from '../../../assets/images/svgicons/package.svg';
import {ReactComponent as OneOffProductIcon} from '../../../assets/images/svgicons/laundry.svg';
import SAGE_LOGO from '../../../assets/images/integrations/sage/SAGE-LOGO.png';
import {
  Button,
  DescriptiveGroup,
  DescriptiveRadio,
  DescriptiveRadioGroup,
  DescriptiveSwitch,
  ImageList,
  ImageThumbnail,
  Switch,
  TextInput,
  ToolTip,
  useWysiwygContext,
  WysiwygEditor,
  WysiwygSubstitution
} from '../../../componentsLib/Basic';
import {Select, SelectItem, UserSelect} from '../../../componentsLib/Pickers';
import {ImageUploadManager} from '../../../componentsLib/DragDrop';
import {Column, Field, FieldColumns, Row, usePageContext} from '../../../componentsLib/Layout';
import {ToolboxSection} from '../../../componentsLib/Popovers';
import {BodyText, CaptionText} from '../../../componentsLib/Text';
import {
  AppAccess,
  DecorationAutoComplete,
  ProductAutoComplete,
  VendorAutoComplete,
  permissionCheck,
  FeatureFlag,
} from '../../../componentsHoops';
import {SalesDocSections, SalesDocSubSections} from '../Components/SalesDocSection';
import {
  inputNameFromEvent,
  MaxFileSize,
  validityFromEvent,
  valueFromEvent
} from '../../../utils';
import {useCompany, useMemoryState, useSnackbar} from '../../../hooks';
import {useListUsers, useSaveCustomer} from '../../../hooks/api';
import {SalesDoc, SalesDocItem} from '../Models/SalesDoc';
import {decorationSubstitutions, oneOffDecorationSubstitutions, oneOffProductSubstitutions, productSubstitutions} from '../../../models/TemplateSubstitutions';
import {SalesDocToolbox} from './SalesDocToolboxDrawer';
import {registerGlobalStyle} from '../../../theme';
import {
  AdditionalCostSettingsSection,
  PresentationCategoriesSection,
  CompanySection,
  CustomerActionsSection,
  CustomerViewSection,
  DecorationSettingsSection,
  DocumentDetailsSection,
  DocumentTermsSection,
  GridPricingSection, ImageSection,
  PresentationColorsAndSizesSection,
  ProductSettingsSection,
  RevenueSummary,
  RowPricingSection,
} from './ToolboxSections';
import {featureFlags} from '../../../utils/featureFlag';

const THUMBNAIL_SIZE = 100;

registerGlobalStyle('.salesdoc-toolbox', (theme) => ({
  '.revenue-summary': {
    '&.has-rollup': {marginLeft: theme.spacing(4)},
    '.text-caption': {flex: '1 1 0',},
    '.text-body': {
      position: 'relative',
      zIndex: 0,
    },
    '.info-tip.tooltip-container': {
      left: theme.spacing(-3),
      width: theme.spacing(3),
      zIndex: -1,
      svg: {
        position: 'absolute',
        left: 0,
      },
    },
  },
  '.row-pricing': {
    'table': {
      'td:last-child input': {minWidth: 'fit-content'},
      'tr td:nth-last-child(4)': {borderRightColor: theme.colors.border.light},
    },
  },
  '&.toolbox-tight table': {
    'th:first-child': {maxWidth: '50px',},
    'td': {
      '.text-input': {
        paddingInline: theme.spacing(.75),
        '&:before': {left: theme.spacing(-.25)},
        '&:after': {marginRight: 0},
        '.clearable-icon': {
          right: theme.spacing(1.25),
          ':is(.percentage-input .clearable-icon)': {right: 0,},
        },
      },
    },
  },
  'table .hover-tools': {
    right: theme.spacing(-2.25),
    top: theme.spacing(.375),
    svg: {fontSize: '1rem'},
  },
  '.field-columns': {
    padding: theme.spacing(2, 0, 1),
    columnGap: theme.spacing(4),
  },
  '.field-group': {
    rowGap: theme.spacing(1.5),
    '+ .field-group': {paddingTop: theme.spacing(3),}
  },
  '.item-details': {
    '.item-detail + .item-detail': {marginTop: theme.spacing(2),},
    '.insert-column': {
      width: theme.spacing(12),
      alignItems: 'center',
      '&.text': {
        marginTop: '-1rem',
        textAlign: 'center',
        lineHeight: '1.2',
        height: '.75rem',
      },
    },
    '.autocomplete': {width: '100%'},
  },
  '.settings': {
    '&>.row': {alignItems: 'center'},
    rowGap: theme.spacing(1),
  },
  '&.things-toolbox': {
    '.things-buttons': {
      button: {
        width: '112px',
        height: '128px',
        borderColor: theme.colors.border.lightest,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        rowGap: theme.spacing(.75),
        'svg': {
          alignSelf: 'center',
          width: 44,
          height: 44,
          color: theme.colors.text.highlight,
        },
        '&>div': {
          paddingTop: theme.spacing(1),
          alignSelf: 'center',
          width: 44,
          height: 44,
          '&>img': {width: 44,},
        },
        '&.disabled': {
          opacity: .5,
          borderColor: 'transparent',
        },
        '&:not(.disabled):hover': {background: theme.colors.background.hover,},
      },
    }
  },
  '&.customer-edit-toolbox': {
    '&>.text-caption': {color: theme.colors.text.main,},
    'textarea': {height: '8em'},
    '.customer-type': {width: 'fit-content'},
    '.contact-row .row': {
      'div:nth-of-type(1), div:nth-of-type(2)': {width: 120},
      'div:nth-of-type(3)': {width: 160},
      'svg': {
        color: theme.colors.text.light,
        alignSelf: 'end',
        marginBottom: '5px',
        cursor: 'pointer'
      },
      margin: theme.spacing(1, 0, 0, 0),
      '.basic-field': {margin: 0}
    },
    '.field-columns': {
      padding: theme.spacing(.5, 0, .5),
      rowGap: theme.spacing(1),
      '.basic-field': {margin: 0}
    },
    '.section-buttons': {padding: theme.spacing(1, 0, 2, 0),},
    '.action-buttons': {
      paddingTop: theme.spacing(2),
      justifyContent: 'space-between'
    }
  },
  '&.product-toolbox': {
    '&.price-locked': {
      '.row-pricing': {
        'th:nth-of-type(7)::after, th:nth-of-type(9)::after': {
          content: '"https"',
          fontFamily: 'Material Icons',
          fontSize: '.8em',
          position: 'absolute',
          margin: '2px 0 0 2px',
        }
      }
    },
  },
  '&.image-toolbox': {
    '.image-upload-manager': {
      '.toolbox-section': {
        width: '100%',
        flex: 'none',
        padding: 0,
      },
    },
    'img, canvas': {
      border: `1px solid ${theme.colors.border.transparent}`,
      transition: theme.transitions.out.border,
    },
  },
}));

registerGlobalStyle('.revenue-summary-tip.tooltip-popover', (theme) => ({marginTop: theme.spacing(1.5)}));

export function useThingsMethods(item) {
  const {salesDoc, setSelection} = usePageContext();

  const addProduct = useCallback(() => {
    const doc = item.addProduct();
    if (doc.newItem) {
      setSelection({section: SalesDocSections.product, groupId: doc.newItem.groupId, itemId: doc.newItem.itemId, variantId: doc.newItem.variantId});
    }
  }, [item, setSelection]);

  const addOneOffProduct = useCallback(() => {
    const doc = item.addOneOffProduct();
    if (doc.newItem) {
      setSelection({section: SalesDocSections.product, groupId: doc.newItem.groupId, itemId: doc.newItem.itemId, variantId: doc.newItem.variantId});
    }
  }, [item, setSelection]);

  const addSageProduct = useCallback(() => {
    const doc = item.addProduct({catalog: 'sage'});
    if (doc.newItem) {
      setSelection({section: SalesDocSections.product, groupId: doc.newItem.groupId, itemId: doc.newItem.itemId, variantId: doc.newItem.variantId});
    }
  }, [item, setSelection]);

  const addDecoration = useCallback(() => {
    const doc = item.addDecoration();
    if (doc.newItem) {
      setSelection({section: SalesDocSections.decoration, groupId: doc.newItem.groupId, itemId: doc.newItem.itemId});
    }
  }, [item, setSelection]);

  const addOneOffDecoration = useCallback(() => {
    const doc = item.addOneOffDecoration();
    if (doc.newItem) {
      setSelection({section: SalesDocSections.decoration, groupId: doc.newItem.groupId, itemId: doc.newItem.itemId});
    }
  }, [item, setSelection]);

  const addAdditionalCost = useCallback(() => {
    const doc = item.addAdditionalCost();
    if (doc.newItem) {
      setSelection({section: SalesDocSections.additionalCost, groupId: doc.newItem.groupId, itemId: doc.newItem.itemId});
    }
  }, [item, setSelection]);

  const addShipping = useCallback(() => {
    const doc = item.addAdditionalCost({description: SalesDocItem.SHIPPING_DESCRIPTION});
    if (doc.newItem) {
      setSelection({section: SalesDocSections.additionalCost, groupId: doc.newItem.groupId, itemId: doc.newItem.itemId});
    }
  }, [item, setSelection]);

  const addGroup = useCallback(() => {
    const doc = salesDoc.addGroup();
    if (doc.newItem) {
      setSelection({section: SalesDocSections.groupSection, groupId: doc.newItem.groupId});
    }
  }, [salesDoc, setSelection]);

  return useMemo(() => ({
    addProduct,
    addOneOffProduct,
    addDecoration,
    addOneOffDecoration,
    addAdditionalCost,
    addShipping,
    addGroup,
    addSageProduct,
  }), [addAdditionalCost, addDecoration, addGroup, addOneOffDecoration, addOneOffProduct, addProduct, addSageProduct, addShipping]);
}

export function ThingsToolbox() {
  const {sage, salesDoc, selection} = usePageContext();

  const variantSelected = salesDoc.getFirstVariantItem(selection.variantId);
  const itemSelected = salesDoc.getItem(selection.itemId);

  const selected = variantSelected ?? itemSelected ?? salesDoc.getFirstItemInGroup(selection.groupId);

  const {addProduct, addOneOffProduct, addDecoration, addOneOffDecoration, addAdditionalCost, addShipping, addGroup, addSageProduct} = useThingsMethods(selected);

  return (
    <SalesDocToolbox className={'things-toolbox'}>
      <RevenueSummary title={'Document Revenue Summary'} summary={salesDoc.summary.getDocumentSummary()} noSep />
      {(!salesDoc.isPresentation() || selection.section === SalesDocSections.product || selection.section === SalesDocSections.productImage) &&
        <ToolboxSection heading={'Add Products'} noSep={salesDoc.isPresentation()}>
          <Row className={'things-buttons'} wrap space>
            <ToolTip bottomLeft tip={'Add a Product from the catalog stored within your Hoops account.'}>
              <Button unstyled onClick={addProduct} prefix={CatalogIcon}>Catalog</Button>
            </ToolTip>
            <ToolTip bottomLeft tip={'Add a Product that is not within your catalog.'}>
              <Button unstyled onClick={addOneOffProduct} prefix={OneOffProductIcon}>One Off</Button>
            </ToolTip>
            {sage &&
              <FeatureFlag allow={featureFlags.sageToolbox}>
                <ToolTip bottomLeft tip={'Add a product by searching the SAGE catalog.'}>
                  <Button unstyled onClick={addSageProduct} prefix={<div><img src={SAGE_LOGO} alt={'SAGE'} /></div>}>SAGE</Button>
                </ToolTip>
              </FeatureFlag>
            }
          </Row>
        </ToolboxSection>
      }
      {(!salesDoc.isPresentation() || selection.section === SalesDocSections.decoration) &&
        <ToolboxSection heading={'Add Decorations'} noSep>
          <Row className={'things-buttons'} wrap space>
            <ToolTip bottomLeft tip={'Add a Decoration from the catalog stored within your Hoops account.'}>
              <Button unstyled onClick={addDecoration} prefix={DecorationIcon}>Catalog</Button>
            </ToolTip>
            <ToolTip bottomLeft tip={'Add a Decoration that is not within your catalog.'}>
              <Button unstyled onClick={addOneOffDecoration} prefix={OneOffDecorationIcon}>One Off</Button>
            </ToolTip>
          </Row>
        </ToolboxSection>
      }
      {(!salesDoc.isPresentation() || selection.section === SalesDocSections.additionalCost) &&
        <ToolboxSection heading={'Add Additional Costs'} noSep>
          <Row className={'things-buttons'} wrap space>
            <ToolTip bottomLeft tip={'Add an Additional Cost. This could be any fixed cost such as handling, shipping, artwork, or setup.'}>
              <Button unstyled onClick={addAdditionalCost} prefix={AdditionalCostIcon}>Additional Cost</Button>
            </ToolTip>
            <ToolTip bottomLeft tip={'Add Shipping costs.'}>
              <Button unstyled onClick={addShipping} prefix={ShippingIcon}>Shipping</Button>
            </ToolTip>
          </Row>
        </ToolboxSection>
      }
      {!salesDoc.isPresentation() &&
        <ToolboxSection heading={'Other'} noSep>
          <Row className={'things-buttons'} wrap space>
            <ToolTip bottomLeft tip={'Add a new group.'}>
              <Button unstyled onClick={addGroup} prefix={AddGroupIcon}>New Group</Button>
            </ToolTip>
          </Row>
        </ToolboxSection>
      }
    </SalesDocToolbox>
  );
}

export function CustomerActionsToolbox() {
  return (
    <SalesDocToolbox className={'customer-actions-toolbox'} heading={'Customer Actions'} noBack>
      <CustomerActionsSection />
    </SalesDocToolbox>
  );
}

export function DocumentHeaderToolbox({onBack}) {
  return (
    <SalesDocToolbox className={'document-header-toolbox'} heading={'Document Header'} noBack={!onBack} onBack={onBack}>
      <CompanySection />
      <DocumentDetailsSection />
    </SalesDocToolbox>
  );
}

export function DocumentFooterToolbox() {
  const {salesDoc} = usePageContext();

  return (
    <SalesDocToolbox className={'document-footer-toolbox'} heading={'Document Footer'} noBack>
      <DocumentTermsSection />
      <ToolboxSection className={'pricing-settings'} heading={'Pricing Settings'} hideShow storageKey={'salesdoc|toolbox|general|pricingSettings'}>
        <Switch label={'Hide Totals'} checked={salesDoc.template.hideTotals} onChange={salesDoc.setTemplateHideTotals} />
      </ToolboxSection>
    </SalesDocToolbox>
  );
}

const _memoryState = {};

export function CustomerToolbox({onBack}) {

  return (
    <SalesDocToolbox heading={'Customer'} noBack={!onBack} onBack={onBack}>
      <CustomerViewSection />
    </SalesDocToolbox>
  );
}

export function CustomerEditToolbox() {
  const {salesDoc, selection, setSelection} = usePageContext();
  const {save: saveCustomerApi, isSaving} = useSaveCustomer();
  const initCustomer = selection.subSection === SalesDocSubSections.addCustomer ? SalesDoc.emptyCustomer : salesDoc?.customer;
  const [customer, setCustomer] = useMemoryState(_memoryState, 'customerDraft', initCustomer);
  const {company} = useCompany(salesDoc.companyTradingEntityId ?? customer?.companyTradingEntityId);
  const entityId = salesDoc.companyTradingEntityId ?? customer?.companyTradingEntityId ?? company.companyTradingEntities?.[0]?._id;
  const isIndividual = useMemo(() => customer?.type === 'INDIVIDUAL', [customer]);
  const contacts = customer?.contacts?.filter((c) => c.deleted !== true);
  const {data: {users = []}} = useListUsers();
  const snackbar = useSnackbar();

  const getIndividualCustomerDetails = useCallback((_contacts) => {
    const firstContact = _contacts.filter((c) => c.deleted !== true)[0];
    return {
      name: `${firstContact.firstName ?? ''} ${firstContact.lastName ?? ''}`,
      email: firstContact?.email,
      phone: firstContact?.phone,
      website: firstContact?.website,
    };
  }, []);

  const handleChangeField = useCallback((e) => {
    setCustomer((prev) => {
      const name = inputNameFromEvent(e);
      const value = valueFromEvent(e);
      return ({...prev, [name]: value, ...(name === 'type' && value === 'INDIVIDUAL' && getIndividualCustomerDetails(prev.contacts))});
    });
  }, [setCustomer, getIndividualCustomerDetails]);

  const handleChangeContactField = useCallback(({e, _id, index}) => {
    setCustomer((prev) => {
      const _contacts = prev.contacts.filter((c) => c.deleted !== true).map((contact, idx) =>
        ((_id && _id === contact._id) || (idx === index))
          ? {...contact, [inputNameFromEvent(e)]: valueFromEvent(e), valid: validityFromEvent(e)}
          : contact);
      return ({...prev, contacts: _contacts, ...(isIndividual) && getIndividualCustomerDetails(_contacts)});
    });
  }, [setCustomer, isIndividual, getIndividualCustomerDetails]);

  const handleAddContact = useCallback(() => {
    setCustomer((prev) => {
      const _contacts = [...prev.contacts];
      _contacts.push({...SalesDoc.emptyContact});
      return ({...prev, contacts: _contacts});
    });
  }, [setCustomer]);

  const handleDeleteContact = useCallback((_id, index) => {
    if (_id) {
      handleChangeContactField({e: {name: 'deleted', value: true}, _id});
    }
    if (!_id) {
      setCustomer((prev) => {
        const _contacts = prev.contacts?.filter((c) => c.deleted !== true).toSpliced(index, 1);
        return ({...prev, contacts: _contacts});
      });
    }
  }, [setCustomer, handleChangeContactField]);

  const handleChangeAddressField = useCallback((e, index) => {
    setCustomer((prev) => {
      const addresses = [...prev.addresses];
      addresses[index][inputNameFromEvent(e)] = valueFromEvent(e);
      return ({...prev, addresses});
    });
  }, [setCustomer]);

  const handleAddAddress = useCallback((index) => {
    setCustomer((prev) => {
      const addresses = prev.addresses.toSpliced(index + 1, 0, {label: '', address1: '', address2: '', city: '', state: '', postcode: '', country: ''});
      return ({...prev, addresses});
    });
  }, [setCustomer]);

  const handleCloneAddress = useCallback((index) => {
    setCustomer((prev) => {
      // eslint-disable-next-line
      const { _id, ...addressObject } = prev.addresses[index];
      const addresses = prev.addresses.toSpliced(index ? index + 1 : 0, 0, {...addressObject});
      return ({...prev, addresses});
    });
  }, [setCustomer]);

  const handleDeleteAddress = useCallback((_id, index) => {
    setCustomer((prev) => {
      const addresses = prev.addresses.toSpliced(index, 1);
      return ({...prev, addresses});
    });
  }, [setCustomer]);

  const handleUnbrandProofPortal = useCallback((_e, value) => {
    setCustomer((prev) => {
      const settings = {...prev.settings, whitelabelProofPortal: value};
      return ({...prev, settings});
    });
  }, [setCustomer]);

  const isContactEmpty = (contact) => {
    for (const key in contact) {
      if (contact[key] !== '' && contact[key] !== null && contact[key] !== undefined) {
        return false;
      }
    }
    return true;
  };

  const handleEditComplete = useCallback(() => {
    setSelection((prev) => ({...prev, subSection: undefined}));
  }, [setSelection]);

  const handleSaveCustomer = useCallback(async () => {
    // Validation for Company Name check to make sure text has been entered not just special characters
    if (!customer.name.replace(/[^\w\s]/gi, '')) {
      snackbar.showSnackbarError('Company name is not valid.');
      return;
    }
    // Validation for contacts looks for empty fields or invalid email
    if (customer.contacts.length === 0) {
      snackbar.showSnackbarError('A Customer must have at least one Contact.');
      return;
    }
    for (const contact of customer.contacts) {
      // Validation for contact first name checks to make sure text has been entered not just special characters
      if (!contact.firstName.replace(/[^\w\s]/gi, '')) {
        snackbar.showSnackbarError('A contacts first name is not valid, please check all contact fields.');
        return;
      }
      if (contact.valid === false || (!contact.firstName || !contact.email)) {
        snackbar.showSnackbarError('Contact is not valid, please check all contact fields.');
        return;
      }
    }

    const {customer: savedCustomer} = await saveCustomerApi({
      _id: customer._id,
      customer: {...customer, contacts: [...customer.contacts.filter((contact) => !isContactEmpty(contact))]}
    });

    const contact = savedCustomer.contacts.find((el) => el._id === salesDoc.contactId) ?? savedCustomer.contacts[0];
    salesDoc.setContact({...contact, customer: savedCustomer});

    await setCustomer(null);
    handleEditComplete();
  }, [handleEditComplete, salesDoc, customer, setCustomer, saveCustomerApi, snackbar]);

  const handleCancelCustomer = useCallback(async () => {
    await setCustomer(null);
    handleEditComplete();
  }, [handleEditComplete, setCustomer]);

  return (
    <SalesDocToolbox className={'customer-edit-toolbox'} heading={customer?._id ? 'Edit Customer' : 'New Customer'} onBack={handleEditComplete}>
      {customer &&
        <>
          <ToolboxSection>
            <DescriptiveRadioGroup caption={'Customer Type'} className={'customer-type'} value={customer.type} onChange={(e) => handleChangeField({name: 'type', value: valueFromEvent(e)})}>
              <DescriptiveRadio caption={'Business / Company'} value={'COMPANY'} />
              <DescriptiveRadio caption={'Individual'} value={'INDIVIDUAL'} />
            </DescriptiveRadioGroup>
          </ToolboxSection>
          <ToolboxSection heading={'Details'} innerSep >
            <FieldColumns columns={1}>
              <Field>
                <CaptionText>Company Name:</CaptionText>
                <TextInput name={'name'} value={customer.name} onChange={handleChangeField} disabled={isIndividual} required />
              </Field>
            </FieldColumns>
            <FieldColumns columns={2}>
              <Field>
                <CaptionText>Email:</CaptionText>
                <TextInput name={'email'} value={customer.email} onChange={handleChangeField} disabled={isIndividual} />
              </Field>
              <Field>
                <CaptionText>Contact Number:</CaptionText>
                <TextInput name={'phone'} value={customer.phone} onChange={handleChangeField} disabled={isIndividual} />
              </Field>
              <Field>
                <CaptionText>Company Website:</CaptionText>
                <TextInput name={'website'} value={customer.website} onChange={handleChangeField} disabled={isIndividual} />
              </Field>
              <Field>
                <CaptionText>Customer Rep:</CaptionText>
                <UserSelect value={customer.customerRepId} onChange={(value) => handleChangeField({name: 'customerRepId', value})} users={users} />
              </Field>
            </FieldColumns>
            <FieldColumns columns={1}>
              <Field>
                <CaptionText>Profile:</CaptionText>
                <TextInput name={'profile'} multiline value={customer.profile} onChange={handleChangeField} />
              </Field>
            </FieldColumns>
          </ToolboxSection>
          <ToolboxSection heading={'Contacts'} innerSep>
            {contacts?.map((cont, index) => (
              <div className={'contact-row'} key={cont._id ?? index}>
                <Row gap>
                  <Field>
                    <CaptionText>First Name:</CaptionText>
                    <TextInput name={'firstName'} value={cont.firstName} onChange={(e) => handleChangeContactField({e, _id: cont._id, index})} required />
                  </Field>
                  <Field>
                    <CaptionText>Last Name:</CaptionText>
                    <TextInput name={'lastName'} value={cont.lastName} onChange={(e) => handleChangeContactField({e, _id: cont._id, index})} />
                  </Field>
                  <Field>
                    <CaptionText>Phone:</CaptionText>
                    <TextInput name={'phone'} value={cont.phone} onChange={(e) => handleChangeContactField({e, _id: cont._id, index})} />
                  </Field>
                  <Field>
                    <CaptionText>Email:</CaptionText>
                    <TextInput name={'email'} value={cont.email} onChange={(e) => handleChangeContactField({e, _id: cont._id, index})} type={'email'} required />
                  </Field>
                  {/* Don't allow all contacts to be deleted, one contact is required */}
                  {index > 0 && <DeleteIcon onClick={() => handleDeleteContact(cont._id, index)} />}
                </Row>
              </div>
            ))}
            <Row gap className={'section-buttons'}>
              {contacts?.length === 0 && <Button text={'Add Contact'} actionPrimary onClick={handleAddContact} />}
              {contacts?.length > 0 && <Button text={'Add Another Contact'} actionPrimary onClick={handleAddContact} />}
            </Row>
          </ToolboxSection>
          <ToolboxSection heading={'Addresses'} hideText={'Hide Addresses'} showText={'Show Addresses'} className={'addresses'} innerSep>
            {customer.addresses?.map((address, index) =>
              <>
                <FieldColumns columns={2}>
                  <Field>
                    <CaptionText>Address Type:</CaptionText>
                    <Select name={'label'} value={address.label} onChange={(value) => handleChangeAddressField({name: 'label', value}, index)}>
                      <SelectItem value={'BILLING'}>
                        <BodyText>Billing</BodyText>
                      </SelectItem>
                      <SelectItem value={'SHIPPING'}>
                        <BodyText>Shipping</BodyText>
                      </SelectItem>
                      <SelectItem value={'OTHER'}>
                        <BodyText>Other</BodyText>
                      </SelectItem>
                    </Select>
                  </Field>
                </FieldColumns>
                <FieldColumns columns={1}>
                  <Field>
                    <CaptionText>Address Line 1:</CaptionText>
                    <TextInput name={'address1'} value={address.address1} onChange={(e) => handleChangeAddressField(e, index)} />
                  </Field>
                  <Field>
                    <CaptionText>Address Line 2:</CaptionText>
                    <TextInput name={'address2'} value={address.address2} onChange={(e) => handleChangeAddressField(e, index)} />
                  </Field>
                </FieldColumns>
                <FieldColumns columns={2}>
                  <Field>
                    <CaptionText>City:</CaptionText>
                    <TextInput name={'city'} value={address.city} onChange={(e) => handleChangeAddressField(e, index)} />
                  </Field>
                  <Field>
                    <CaptionText>State:</CaptionText>
                    <TextInput name={'state'} value={address.state} onChange={(e) => handleChangeAddressField(e, index)} />
                  </Field>
                  <Field>
                    <CaptionText>Postcode:</CaptionText>
                    <TextInput name={'postcode'} value={address.postcode} onChange={(e) => handleChangeAddressField(e, index)} />
                  </Field>
                  <Field>
                    <CaptionText>Country:</CaptionText>
                    <TextInput name={'country'} value={address.country} onChange={(e) => handleChangeAddressField(e, index)} />
                  </Field>
                </FieldColumns>
                <Row gap className={'section-buttons'}>
                  <Button text='Add Another Address' actionPrimary onClick={() => handleAddAddress(index)} />
                  <Button text='Clone this Address' actionPrimary onClick={() => handleCloneAddress(index)} />
                  <Button text='Delete this Address' actionPrimary onClick={() => handleDeleteAddress(address._id, index)} />
                </Row>
              </>
            )}
            <Row gap className={'section-buttons'}>
              {customer.addresses?.length === 0 && <Button text={'Add Address'} actionPrimary onClick={handleAddAddress} />}
            </Row>
          </ToolboxSection>
          <ToolboxSection heading={'Settings'} hideText={'Hide Settings'} showText={'Show Settings'} innerSep>
            <FieldColumns columns={1}>
              <DescriptiveGroup>
                <DescriptiveSwitch caption={'Unbranded Proof Portal'} checked={customer.settings?.whitelabelProofPortal} onChange={handleUnbrandProofPortal}>
                  This will remove your company branding from the proof portal.
                </DescriptiveSwitch>
              </DescriptiveGroup>
            </FieldColumns>
            {permissionCheck({allowedAppAccess: [AppAccess.MultiCompany]}) &&
              <FieldColumns columns={1}>
                <div>
                  <CaptionText>Company Branding:</CaptionText>
                  <Select value={entityId} onChange={(value) => handleChangeField({name: 'companyTradingEntityId', value})}>
                    {company.companyTradingEntities.map((ent) => (
                      <SelectItem key={ent._id} value={ent._id} text={ent.name} />
                    ))}
                  </Select>
                </div>
              </FieldColumns>
            }
          </ToolboxSection>
          <ToolboxSection stickyFooter>
            <Row className={'action-buttons'}>
              <Button text='Cancel' navNegative onClick={handleCancelCustomer} />
              <Button text='Save' loading={isSaving} navPositive onClick={handleSaveCustomer} />
            </Row>
          </ToolboxSection>
        </>
      }
    </SalesDocToolbox>
  );
}

export function ProductToolbox() {
  const {salesDoc, selection, setSelection} = usePageContext();
  const [vendorSearch, setVendorSearch] = useState('');
  const [productSearch, setProductSearch] = useState('');

  const items = salesDoc.getVariantItems(selection.variantId);
  const firstItem = items[0];
  const summary = salesDoc.summary.getVariantSummary(firstItem.variantId);
  const product = firstItem.productId ? salesDoc.products[firstItem.productId] : null;
  const oneOff = firstItem.type === SalesDocItem.Type.VARIANT && product == null;
  const searchOnly = firstItem.type === SalesDocItem.Type.PRODUCT_PLACEHOLDER;
  const isPresentation = salesDoc.documentType === SalesDoc.Type.PRESENTATION;

  useEffect(() => {
    setVendorSearch('');
    setProductSearch('');
  }, [firstItem?.itemId]);

  const setProduct = useCallback((newProduct) => {
    firstItem.setProduct(newProduct);
    setSelection({section: SalesDocSections.product, groupId: firstItem.groupId, itemId: firstItem.itemId, variantId: firstItem.variantId});
  }, [firstItem, setSelection]);

  const handleAddVendor = useCallback(() => {
    setSelection((prev) => ({...prev, subSection: SalesDocSubSections.addVendor}));
  }, [setSelection]);

  return (
    <SalesDocToolbox
      className={['product-toolbox', firstItem.priceMode === SalesDocItem.PriceMode.AVERAGE && 'price-locked']}
      heading={oneOff ? 'One Off Product' : 'Catalog Product'}
      noBack={isPresentation}
    >
      <RevenueSummary summary={summary} />
      {!oneOff &&
        <ToolboxSection heading={'Search for a Product'}>
          <ProductAutoComplete
            placeholder='Search for Product Name, Code, Vendor, or Category'
            onChange={setProduct}
            product={product}
            search={productSearch}
            onSearchChange={setProductSearch}
          />
        </ToolboxSection>
      }
      {oneOff &&
        <ToolboxSection>
          <FieldColumns columns={'2fr 1fr 1fr'}>
            <div>
              <TextInput label={'Product Name'} value={firstItem.name} onChange={firstItem.setName} />
            </div>
            <div>
              <TextInput label={'Code'} value={firstItem.code} onChange={firstItem.setCode} />
            </div>
            <div>
              <VendorAutoComplete
                label={'Vendor'}
                vendor={firstItem.vendor}
                onChange={firstItem.setVendor}
                search={vendorSearch}
                onSearchChange={setVendorSearch}
                onAddNewVendor={handleAddVendor}
              />
            </div>
          </FieldColumns>
        </ToolboxSection>
      }
      {(!searchOnly || oneOff) &&
        <>
          {isPresentation &&
            <PresentationCategoriesSection />
          }
          {isPresentation &&
            <ToolboxSection heading={'Title'}>
              <TextInput value={firstItem.name} onChange={firstItem.setName} />
            </ToolboxSection>
          }
          {!oneOff &&
            <>
              <ToolboxSection
                heading={'Description'}
                innerSep
                hideText={'Hide Description Settings'}
                showText={'Show Description Settings'}
                storageKey={'salesdoc|toolbox|product|description'}
              >
                <WysiwygSubstitution fields={product} substitutions={productSubstitutions} />
              </ToolboxSection>
              <ToolboxSection noSep>
                <WysiwygEditor value={firstItem.description} onChange={firstItem.setDescription} />
              </ToolboxSection>
            </>
          }
          {oneOff &&
            <>
              <ToolboxSection
                heading={'Description'}
                innerSep
                hideText={'Hide Description Settings'}
                showText={'Show Description Settings'}
                storageKey={'salesdoc|toolbox|product|description'}
              >
                <WysiwygSubstitution fields={firstItem} substitutions={oneOffProductSubstitutions} />
              </ToolboxSection>
              <ToolboxSection noSep>
                <WysiwygEditor value={firstItem.description} onChange={firstItem.setDescription} />
              </ToolboxSection>
            </>
          }
          {!isPresentation &&
            <RowPricingSection heading={'Variant Pricing'} items={items} oneOff={oneOff} />
          }
          {isPresentation &&
            <GridPricingSection items={items} />
          }
          {isPresentation &&
            <PresentationColorsAndSizesSection item={firstItem} oneOff={oneOff}/>
          }
          <ImageSection item={firstItem}/>
          <ProductSettingsSection item={firstItem} />
        </>
      }
    </SalesDocToolbox>
  );
}

export function DecorationToolbox() {
  const {salesDoc, selection, setSelection, uploadCache} = usePageContext();
  const [vendorSearch, setVendorSearch] = useState('');
  const [decorationSearch, setDecorationSearch] = useState('');

  const isPresentation = salesDoc.documentType === SalesDoc.Type.PRESENTATION;
  const decorationItem = salesDoc.getItem(selection.itemId) ?? salesDoc.getFirstVariantItem(selection.variantId);
  const decorationItems = isPresentation ? salesDoc.getVariantItems(decorationItem.variantId) : [decorationItem];
  const summary = decorationItem && salesDoc.summary.getItemSummary(decorationItem.itemId);
  const oneOff = decorationItem.type === SalesDocItem.Type.DECORATION && decorationItem.decorationId == null;
  const searchOnly = decorationItem.type === SalesDocItem.Type.DECORATION_PLACEHOLDER;
  const hasVariants = salesDoc.groupHasProductVariants(decorationItem.groupId);
  const linkedProducts = salesDoc.getProductsInGroup(decorationItem.groupId).filter((product) => product.decorations?.length > 0);

  useEffect(() => {
    setVendorSearch('');
    setDecorationSearch('');
  }, [decorationItem?.itemId]);

  const handleAddVendor = useCallback(() => {
    setSelection((prev) => ({...prev, subSection: SalesDocSubSections.addVendor}));
  }, [setSelection]);

  const images = uploadCache.files.filter((file) => file.url).map((file) => (file.url));
  if (isPresentation) {
    decorationItem.images?.forEach((image) => {
      if (!images.includes(image)) {
        images.push(image);
      }
    });
  } else {
    if (decorationItem.image && !images.includes(decorationItem.image)) {
      images.splice(0, 0, decorationItem.image);
    }
  }

  return (
    <SalesDocToolbox className={'decoration-toolbox'} heading={oneOff ? 'One Off Decoration' : 'Catalog Decoration'} noBack={isPresentation}>
      {!isPresentation &&
        <RevenueSummary summary={summary} />
      }
      {!oneOff &&
        <ToolboxSection heading={'Search for a Decoration'}>
          <DecorationAutoComplete
            decoration={decorationItem.decoration}
            placeholder='Search for a Decoration by Name, Category, or Vendor'
            onChange={decorationItem.setDecoration}
            search={decorationSearch}
            onSearchChange={setDecorationSearch}
            catalog
            products={linkedProducts}
          />
        </ToolboxSection>
      }
      {oneOff &&
        <ToolboxSection>
          <FieldColumns columns={2}>
            <div>
              <TextInput label={'Decoration Name'} value={decorationItem.name} onChange={decorationItem.setName} />
            </div>
            <div>
              <VendorAutoComplete
                label={'Vendor'}
                vendor={decorationItem.vendor}
                onChange={decorationItem.setVendor}
                search={vendorSearch}
                onSearchChange={setVendorSearch}
                onAddNewVendor={handleAddVendor}
              />
            </div>
          </FieldColumns>
        </ToolboxSection>
      }
      {(!searchOnly || oneOff) &&
        <>
          {!isPresentation &&
            <RowPricingSection heading={'Decoration Pricing'} items={decorationItems} oneOff={oneOff} fixedQuantity={hasVariants} />
          }
          {isPresentation &&
            <GridPricingSection items={decorationItems} />
          }
          <ToolboxSection className={'decoration-specs'} heading={'Decoration Specs'} noSep>
            <Column className={'item-details'}>
              <Column className={'item-detail'}>
                <CaptionText>Position:</CaptionText>
                <TextInput value={decorationItem.position} onChange={decorationItem.setPosition} />
              </Column>
              <Column className={'item-detail'}>
                <CaptionText>Color:</CaptionText>
                <TextInput value={decorationItem.color} onChange={decorationItem.setColor} />
              </Column>
              <Column className={'item-detail'}>
                <CaptionText>Size:</CaptionText>
                <TextInput value={decorationItem.size} onChange={decorationItem.setSize} />
              </Column>
            </Column>
          </ToolboxSection>
          <ToolboxSection heading={'Description'} noSep>
            <WysiwygSubstitution fields={decorationItem} substitutions={oneOff ? oneOffDecorationSubstitutions : decorationSubstitutions} />
            <WysiwygEditor value={decorationItem.description} onChange={decorationItem.setDescription} />
          </ToolboxSection>
          <ToolboxSection heading={isPresentation ? 'Images' : 'Image'} noSep>
            <ImageUploadManager
              acceptPDF
              images={images}
              value={isPresentation ? decorationItem.images : decorationItem.image}
              maxSize={MaxFileSize.Max10Meg}
              multiSelect={isPresentation}
              onChange={isPresentation ? decorationItem.setImages : decorationItem.setImage}
              onUploadComplete={isPresentation ? decorationItem.setImages : decorationItem.setImage}
              uploadCache={uploadCache}
            />
          </ToolboxSection>
          <DecorationSettingsSection item={decorationItem} />
        </>
      }
    </SalesDocToolbox>
  );
}

export function AdditionalCostToolbox() {
  const {salesDoc, selection, setSelection, uploadCache} = usePageContext();
  const [vendorSearch, setVendorSearch] = useState('');
  const {insertText} = useWysiwygContext();

  const isPresentation = salesDoc.documentType === SalesDoc.Type.PRESENTATION;
  const firstItem = salesDoc.getItem(selection.itemId) ?? salesDoc.getFirstVariantItem(selection.variantId);
  const items = isPresentation ? salesDoc.getVariantItems(firstItem.variantId) : [firstItem];
  const summary = firstItem && salesDoc.summary.getItemSummary(firstItem.itemId);

  const isShipping = firstItem.description === SalesDocItem.SHIPPING_DESCRIPTION;

  useEffect(() => {
    setVendorSearch('');
  }, [firstItem?.itemId]);

  const handleAddVendor = useCallback(() => {
    setSelection((prev) => ({...prev, subSection: SalesDocSubSections.addVendor}));
  }, [setSelection]);

  const images = uploadCache.files.filter((file) => file.url).map((file) => (file.url));
  if (isPresentation) {
    firstItem.images?.forEach((image) => {
      if (!images.includes(image)) {
        images.push(image);
      }
    });
  } else {
    if (firstItem.image && !images.includes(firstItem.image)) {
      images.splice(0, 0, firstItem.image);
    }
  }

  return (
    <SalesDocToolbox className={'additional-cost-toolbox'} heading={'Additional Cost'} noBack={isPresentation}>
      {!isPresentation &&
        <RevenueSummary summary={summary} />
      }
      {!isPresentation &&
        <RowPricingSection items={items} oneOff={true} fixedQuantity={true} />
      }
      {isPresentation &&
        <GridPricingSection items={items} noSep={false} />
      }
      {!isShipping &&
        <ToolboxSection className={'additional-cost-details'} heading={'Details'} noSep>
          <Column className={'item-details'}>
            <Row justifyRight>
              <CaptionText className={'insert-column'}>Add to Description</CaptionText>
            </Row>
            <Column className={'item-detail'}>
              <CaptionText>Additional Cost Name:</CaptionText>
              <Row>
                <TextInput value={firstItem.name} onChange={firstItem.setName} />
                <Row className={'insert-column'} justifyCenter>
                  <Button navMain prefix={InsertTextIcon} tabIndex={-1} onClick={() => insertText({text: `${firstItem.name}`, addToNewLine: true})} />
                </Row>
              </Row>
            </Column>
            <Column className={'item-detail'}>
              <CaptionText>Vendor:</CaptionText>
              <Row>
                <VendorAutoComplete
                  vendor={firstItem.vendor}
                  onChange={firstItem.setVendor}
                  search={vendorSearch}
                  onSearchChange={setVendorSearch}
                  onAddNewVendor={handleAddVendor}
                />
                <Row className={'insert-column'} justifyCenter>
                  <Button navMain prefix={InsertTextIcon} tabIndex={-1} onClick={() => insertText({text: `${firstItem.vendorName}`, addToNewLine: true})} />
                </Row>
              </Row>
            </Column>
          </Column>
        </ToolboxSection>
      }
      <ToolboxSection heading={'Description'} noSep>
        {!isShipping && <WysiwygEditor value={firstItem.description} onChange={firstItem.setDescription} />}
        {isShipping && <TextInput value={firstItem.description} disabled />}
      </ToolboxSection>
      <ToolboxSection heading={isPresentation ? 'Images' : 'Image'} noSep>
        <ImageUploadManager
          acceptPDF
          images={images}
          value={isPresentation ? firstItem.images : firstItem.image}
          maxSize={MaxFileSize.Max10Meg}
          multiSelect={isPresentation}
          onChange={isPresentation ? firstItem.setImages : firstItem.setImage}
          onUploadComplete={isPresentation ? firstItem.setImages : firstItem.setImage}
          uploadCache={uploadCache}
        />
      </ToolboxSection>
      <AdditionalCostSettingsSection item={firstItem} />
    </SalesDocToolbox>
  );
}

export function ImageToolbox() {
  const {salesDoc, selection, setSelection, uploadCache} = usePageContext();

  const [item] = salesDoc.getVariantItems(selection.variantId);
  const product = salesDoc.products[item.productId];

  const handleBackButton = useCallback(() => {
    setSelection((prevSelection) => ({...prevSelection, section: SalesDocSections.product}));
  }, [setSelection]);

  const handleClickImage = useCallback((image) => {
    if (salesDoc.documentType !== SalesDoc.Type.PRESENTATION) {
      if (image) {
        item.setImage(image);
      }
      setSelection((prevSelection) => ({...prevSelection, section: SalesDocSections.product}));
    } else {
      const index = item.images?.indexOf(image);
      if (index >= 0) {
        item.setImages(item.images.toSpliced(index, 1));
      } else {
        item.setImages([...item.images ?? [], image]);
      }
    }
  }, [item, salesDoc.documentType, setSelection]);

  const handleSetImages = useCallback((images) => {
    item.setImages(images);
  }, [item]);

  const handleUploadImage = useCallback((image) => {
    if (image) {
      item.setImage(image);
    }
  }, [item]);

  const uploadImages = uploadCache.files.filter((file) => file.url).map((file) => (file.url));
  const namedImages = product ? Object.entries(product.namedImages) : [];

  const isPresentation = salesDoc.documentType === SalesDoc.Type.PRESENTATION;
  const selectedImages = item.images ?? [];

  // Add any images that are in teh salesdoc, but are not in the product, to the uploaded images list
  const checkImages = isPresentation ? selectedImages : [item.image];
  checkImages.forEach((checkImage) => {
    if (!uploadImages.includes(checkImage) && !namedImages.some(([, image]) => image === checkImage) && product?.primaryImage !== checkImage) {
      uploadImages.push(checkImage);
    }
  });

  return (
    <SalesDocToolbox className={'image-toolbox'} heading={'Image Toolbox'} onBack={handleBackButton}>
      <ToolboxSection>
        <ImageUploadManager
          acceptPDF
          images={uploadImages}
          multiSelect={isPresentation}
          maxSize={MaxFileSize.Max10Meg}
          value={isPresentation ? selectedImages : item.image}
          onChange={isPresentation ? handleSetImages : handleClickImage}
          onUploadComplete={isPresentation ? handleSetImages : handleUploadImage}
          uploadCache={uploadCache}
          dropZoneFirst
          hoverZoom
          hoverOutline
        >
          {uploadImages.length > 0 &&
            <ToolboxSection noSep heading={'Uploaded Image(s)'} />
          }
        </ImageUploadManager>
      </ToolboxSection>
      {product?.primaryImage &&
        <ToolboxSection noSep heading={'Product Primary Image'}>
          <ImageThumbnail
            hoverOutline
            hoverZoom
            size={THUMBNAIL_SIZE}
            selected={selectedImages.includes(product.primaryImage)}
            imageUrl={product.primaryImage}
            uploaded={product.primaryImage && uploadCache.urls.includes(product.primaryImage)}
            onClick={() => handleClickImage(product.primaryImage)}
          />
        </ToolboxSection>
      }
      {namedImages?.length > 0 &&
        <ToolboxSection noSep heading={`Product Variant Images (${namedImages.length})`}>
          <ImageList threshold={14}>
            {namedImages.map(([name, image]) => (
              <ImageThumbnail
                key={name}
                hoverOutline
                hoverZoom
                imageUrl={image}
                size={THUMBNAIL_SIZE}
                caption={name}
                selected={(image === item.image && !isPresentation) || selectedImages.includes(image)}
                onClick={() => handleClickImage(image)}
              />
            ))}
          </ImageList>
        </ToolboxSection>
      }
    </SalesDocToolbox>
  );
}

export function GroupToolbox() {
  const {salesDoc, selection} = usePageContext();
  const summary = salesDoc.summary.getGroupSummary(selection.groupId);
  return (
    <SalesDocToolbox className={'group-toolbox'}>
      <RevenueSummary title={'Group Revenue Summary'} summary={summary} noSep />
    </SalesDocToolbox>
  );
}

// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import {Grid, Link} from '@material-ui/core';
import React from 'react';
import {connect} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import {openCustomerModal as _openCustomerModal} from '../../actions/modals';
import {Button} from '../../componentsLib/Basic';
import {Row} from '../../componentsLib/Layout';
import {registerGlobalStyle} from '../../theme';
import EntityListHero from '../EntityList/EntityListHero';
import CustomerTable from './CustomerManyTable';

registerGlobalStyle('.hero-button-row', (theme) => ({
  '&.row-gap': {
    columnGap: theme.spacing(1),
    '.busy-spinner': {top: theme.spacing(1.5)}
  },
  'a': {textDecoration: 'none'}
}));

class CustomersTab extends React.Component {
  state = {
    isCreateCustomerOpen: false,
    customerExportLoading: false
  };

  openCreateCustomer = () => {
    const {openCustomerModal} = this.props;

    openCustomerModal();
  };

  handleCreateCustomerClose = () => {
    this.setState({isCreateCustomerOpen: false});
  };

  componentDidMount() {
    document.title = 'Customers | Hoops';
  }

  render() {
    const {openCustomerModal} = this.props;
    return (
      <Grid container>
        <EntityListHero
          title='Customers'
          subtitle='Import, manage &amp; access all your customer information at any time.'
          helpArticleUrl='http://help.hoopscrm.com/en/articles/4632961-adding-companies-customers'
          videoId='oJjG9FUEMUo'
        >
          <Row gap className={'hero-button-row'}>
            <a download={'customers.csv'} href={`${process.env.REACT_APP_BASE_URL}/rest/exports/customers`}>
              <Button navStandard >Export</Button>
            </a>
            <Link component={RouterLink} to='/customers/import' underline='none'>
              <Button navStandard>Import</Button>
            </Link>
            <Button data-intercom-target={'customers-table-add-customer'} navMain onClick={openCustomerModal}>Add Customer</Button>
          </Row>
        </EntityListHero>
        <Grid item xs={12}>
          <CustomerTable openCreateCustomer={this.openCreateCustomer} />
        </Grid>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {openCustomerModal: () => dispatch(_openCustomerModal())};
}

CustomersTab = connect(null, mapDispatchToProps)(CustomersTab);

export default CustomersTab;

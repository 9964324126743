import React from 'react';
import {SalesDocToolbox} from './SalesDocToolboxDrawer';
import {CompanySection, CustomerViewSection, DocumentDetailsSection, PresentationBlockSection} from './ToolboxSections';
import {usePageContext} from '../../../componentsLib/Layout';

export function PresentationToolbox() {
  const {salesDoc} = usePageContext();

  return (
    <>
      {salesDoc?.template &&
        <SalesDocToolbox heading={'SalesPresentation'} noBack>
          <CustomerViewSection />
          <CompanySection />
          <DocumentDetailsSection />
          <PresentationBlockSection
            section={'header'}
            image={salesDoc.template.headerImage}
            text={salesDoc.template.headerText}
            showBlock={salesDoc.template.showHeaderBlock}
            setShowBlock={salesDoc.setTemplateShowHeaderBlock}
            onImageChange={salesDoc.setTemplateHeaderBlockImage}
            onTextChange={salesDoc.setHeaderBlockText}
          />
          <PresentationBlockSection
            section={'title'}
            image={salesDoc.template.titleImage}
            text={salesDoc.template.titleText}
            showBlock={salesDoc.template.showTitleBlock}
            setShowBlock={salesDoc.setTemplateShowTitleBlock}
            onImageChange={salesDoc.setTemplateTitleBlockImage}
            onTextChange={salesDoc.setTitleBlockText}
          />
          <PresentationBlockSection
            section={'footer'}
            image={salesDoc.template.footerImage}
            text={salesDoc.template.footerText}
            showBlock={salesDoc.template.showFooterBlock}
            setShowBlock={salesDoc.setTemplateShowFooterBlock}
            onImageChange={salesDoc.setTemplateFooterBlockImage}
            onTextChange={salesDoc.setFooterBlockText}
          />
        </SalesDocToolbox>
      }
    </>
  );
}

import React from 'react';
import {BodyText} from '../Text';
import {HoopsPropTypes} from '../utils';

export function WysiwygText({text}) {
  return (
    <BodyText className={'text-wysiwyg'}><span dangerouslySetInnerHTML={{__html: text}} /></BodyText>
  );
}

WysiwygText.propTypes = {text: HoopsPropTypes.string};

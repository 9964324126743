import React, {useRef, useState} from 'react';
import classNames from 'classnames';
import {CloudUpload as UploadIcon} from '@mui/icons-material';
import {Document, Page} from 'react-pdf/dist/cjs/entry.webpack';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import {CaptionText} from '../Text';
import {ToolTip} from './ToolTip';

registerGlobalStyle('.image-thumbnail', (theme) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'var(--img-thumb-width)',
  height: 'var(--img-thumb-height)',
  margin: 0,
  padding: 0,
  flexDirection: 'column',
  zIndex: 0, // Give the image thumbnail its own stacking context so the checkbox does not appear over other elements

  '.react-pdf__Document': {
    '.react-pdf__Page': {
      '.react-pdf__Page__canvas': {
        maxWidth: 'var(--img-thumb-width)',
        maxHeight: 'var(--img-thumb-height)',
        width: 'unset !important',
        height: 'unset !important',
      },
    }
  },

  '&.hover-outline': {
    border: '1px solid transparent',
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.in.border,
    '&:hover': {
      borderColor: theme.colors.border.highlight,
      transition: theme.transitions.out.border,
    },
  },

  '&.selectable': {
    cursor: 'pointer',
    '&:before': {
      content: '"circle"',
      color: theme.colors.palette.white,
    },
    '&:after': {
      content: '"check_circle"',
      color: theme.colors.palette.green,
    },
    '&:before, &:after': {
      fontFamily: 'Material Icons',
      fontSize: '1.75rem',
      position: 'absolute',
      width: 24,
      height: 24,
      lineHeight: 1,
      top: -10,
      right: -6,
      opacity: 0,
      zIndex: 1,
    },
    '&.selected': {'&:before, &:after': {opacity: 1,},},
  },

  '.uploaded-icon': {
    position: 'absolute',
    bottom: -5,
    right: -3,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    background: theme.colors.background.white,
    border: `1px solid ${theme.colors.border.main}`,
    zIndex: 1,
    '&>svg': {
      width: 16,
      height: 16,
      color: theme.colors.border.main,
    },
  },

  '&:has(>.text-caption)': {marginBottom: theme.spacing(3)},
  '.text-caption': {
    display: 'block',
    position: 'absolute',
    top: 'var(--img-thumb-height)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    textAlign: 'center',
    width: '100%',
    padding: theme.spacing(0, 1),
  }
}));

registerGlobalStyle('.image-thumbnail-tooltip.tooltip-popover', (theme) => ({
  '&.image-thumbnail-tooltip.image-thumbnail-tooltip': {
    display: 'flex',
    margin: '0 8px',
    padding: 0,
    maxWidth: 'none',
    boxShadow: theme.shadows.popoverElevation,
  },
  'img': {
    maxWidth: 400,
    borderRadius: theme.shape.borderRadius,
  },
}));

export function ImageThumbnail({className, imageUrl, caption, contentType, hoverZoom, hoverOutline, selected, uploaded, size, width, height, onClick}) {
  className = classNames([
    className,
    'image-thumbnail',
    hoverOutline && 'hover-outline',
    (selected != null || onClick) && 'selectable',
    selected && 'selected',
    uploaded && 'uploaded',
    caption && 'captioned',
  ]);

  const thumbnailRef = useRef();
  const [imageTypeLoadFailed, setImageTypeLoadFailed] = useState(false);

  width = width ?? size ? `${width ?? size}px` : '100%';
  height = height ?? size ? `${height ?? size}px` : '100%';

  return (
    <div ref={thumbnailRef} className={className} style={{'--img-thumb-width': width, '--img-thumb-height': height}} onClick={onClick}>

      {(contentType !== 'application/pdf' && !imageTypeLoadFailed) &&
        <ToolTip className={'image-thumbnail-tooltip'} right tip={hoverZoom ? <img src={imageUrl} alt='' /> : ''} arrow={false}>
          <img src={imageUrl} alt='' style={{maxWidth: width ?? size, maxHeight: height ?? size}} onError={() => setImageTypeLoadFailed(true)} />
        </ToolTip>
      }

      {(contentType === 'application/pdf' || imageTypeLoadFailed) &&
        <ToolTip
          className={'image-thumbnail-tooltip'}
          right
          arrow={false}
          tip={hoverZoom ? <Document file={imageUrl}><Page pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false} width={300} /></Document> : ''}
        >
          <Document file={imageUrl}>
            <Page pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false} />
          </Document>
        </ToolTip>
      }

      {uploaded &&
        <div className={'uploaded-icon'}>
          <UploadIcon />
        </div>
      }

      {caption &&
        <CaptionText>{caption}</CaptionText>
      }

    </div>
  );
}

ImageThumbnail.propTypes = {
  className: HoopsPropTypes.className,
  imageUrl: HoopsPropTypes.string,
  caption: HoopsPropTypes.string,
  contentType: HoopsPropTypes.string,
  hoverZoom: HoopsPropTypes.bool,
  hoverOutline: HoopsPropTypes.bool,
  selected: HoopsPropTypes.bool,
  size: HoopsPropTypes.number,
  uploaded: HoopsPropTypes.bool,
  width: HoopsPropTypes.number,
  height: HoopsPropTypes.number,
  onClick: HoopsPropTypes.func,
};

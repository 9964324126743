import React from 'react';
import classNames from 'classnames';
import {HoopsPropTypes} from '../utils';
import {registerGlobalStyle} from '../../theme';

const plus1_128th = '0.0078125em';

registerGlobalStyle('.text-heading', (theme) => ({
  fontFamily: theme.typography.fontFamily,
  margin: '0 0 0.35em',
  width: '100%',
  '&:is(h2)': {
    fontSize: '3rem',
    lineHeight: '1.1875',
    letterSpacing: '0em',
  },
  '&:is(h3)': {
    fontSize: '2rem',
    lineHeight: '1.25',
    letterSpacing: plus1_128th,
  },
  '&:is(h4)': {
    fontSize: '1.5rem',
    lineHeight: '1.375',
    letterSpacing: plus1_128th,
  },
  '&:is(h5)': {
    fontSize: '1.25rem',
    lineHeight: '1.625',
    letterSpacing: plus1_128th,
  },
  '&:is(h6)': {
    fontSize: '1rem',
    lineHeight: '1.75',
    letterSpacing: plus1_128th,
  },
  '&.subheading': {
    fontSize: '.875rem',
    lineHeight: '1.5',
  },
  '&.light': {fontWeight: theme.typography.light,},
  '&.normal': {fontWeight: theme.typography.normal,},
  '&.dark': {fontWeight: theme.typography.dark,},
  '&.highlight': {color: theme.colors.text.highlight},
  '&.center': {
    width: '100%',
    textAlign: 'center',
  },
  '&.fit': {width: 'unset'},
}));

export function HeadingText({
                              className,
                              x14, x16, x20, x24, x32, x48,
                              light, normal, dark,
                              section, subHeading,
                              highlight,
                              heading, text,
                              center,
                              currency,
                              fit,
                              children
}) {
  let Tag = 'h5';
  let weight = null;
  let style = null;
  if (x48) {
    Tag = 'h2';
    weight = 'normal';
  } else if (x32) {
    Tag = 'h3';
    weight = 'normal';
  } else if (x24) {
    Tag = 'h4';
    weight = 'light';
  } else if (x20 || section) {
    Tag = 'h5';
    weight = 'dark';
  } else if (x16) {
    Tag = 'h6';
    weight = 'dark';
  } else if (x14 || subHeading) {
    Tag = 'h6';
    weight = 'dark';
    style = 'subheading';
  }
  if (light) {
    weight = 'light';
  } else if (normal) {
    weight = 'normal';
  } else if (dark) {
    weight = 'dark';
  }
  return (
    <Tag
      className={classNames([
        className,
        'text text-heading',
        weight,
        highlight && 'highlight',
        currency && 'currency',
        center && 'center',
        fit && 'fit',
        style
      ])}
    >
      {heading ?? text ?? children}
    </Tag>
  );
}

HeadingText.propTypes = {
  className: HoopsPropTypes.className,
  x16: HoopsPropTypes.bool,
  x20: HoopsPropTypes.bool,
  x24: HoopsPropTypes.bool,
  x32: HoopsPropTypes.bool,
  x48: HoopsPropTypes.bool,
  section: HoopsPropTypes.bool,
  subHeading: HoopsPropTypes.bool,
  light: HoopsPropTypes.bool,
  normal: HoopsPropTypes.bool,
  dark: HoopsPropTypes.bool,
  highlight: HoopsPropTypes.bool,
  heading: HoopsPropTypes.string,
  text: HoopsPropTypes.string,
  center: HoopsPropTypes.bool,
  currency: HoopsPropTypes.bool,
  fit: HoopsPropTypes.bool,
  children: HoopsPropTypes.children,
};

import React from 'react';
import classNames from 'classnames';
import {registerGlobalStyle} from '../../theme';
import {asCurrencyString} from '../../utils';
import {CurrencyRateInput} from '../Basic';
import {AutoCompleteContainer, AutoCompleteList, AutoCompleteListItem, AutoCompletePopover, AutoCompleteValueContainer} from '../Popovers';
import {BodyText} from '../Text';
import {HoopsPropTypes} from '../utils';

registerGlobalStyle('.currency-rate-picker', () => ({'.currency-rate-value': {margin: 0},}));

export function CurrencyRatePicker({className, clearable, onChange, onClear, readOnly, value, options}) {
  return (
    <AutoCompleteContainer
      className={[className, 'currency-rate-picker']}
      value={value}
      onChange={onChange}
    >
      <AutoCompleteValueContainer className={classNames([className, 'currency-rate-value'])} showOpenArrow>
        <CurrencyRateInput
          value={value}
          onChange={onChange}
          clearable={clearable}
          onClear={onClear}
          readOnly={readOnly}
        />
      </AutoCompleteValueContainer>
      {options?.length > 0 &&
        <AutoCompletePopover className={'currency-rate-popover'}>
          <AutoCompleteList>
            {options.map((option) => (
              <AutoCompleteListItem key={option.value} value={option.value.toString()}>
                <BodyText currency text={asCurrencyString(option.value)} />
                {option.text && <BodyText small text={option.text} />}
              </AutoCompleteListItem>
            ))}
          </AutoCompleteList>
        </AutoCompletePopover>
      }
    </AutoCompleteContainer>
  );
}

CurrencyRatePicker.propTypes = {
  className: HoopsPropTypes.className,
  clearable: HoopsPropTypes.bool,
  onChange: HoopsPropTypes.func,
  onClear: HoopsPropTypes.func,
  readOnly: HoopsPropTypes.bool,
  value: HoopsPropTypes.stringOrNumber,
  options: HoopsPropTypes.array,
};

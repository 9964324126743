import * as React from 'react';
import classNames from 'classnames';
import {registerGlobalStyle} from '../../../theme';
import {forwardRef, useCallback, useState} from 'react';
import {HoopsPropTypes} from '../../utils';

registerGlobalStyle('.power-grid .power-grid-cell', (theme) => ({
  '&.MuiDataGrid-cell': {
    position: 'relative',
    overflow: 'visible',
    '&:is(.MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell)': {overflow: 'visible'},
    paddingBlock: theme.spacing(.5),
    borderRight: `1px solid ${theme.colors.border.lightestRgb}`,
    borderBottom: `1px solid ${theme.colors.border.lightestRgb}`,
  },
  '&:last-child': {borderRight: 'none',},
  '&.MuiDataGrid-cell:focus, &.MuiDataGrid-cell:focus-within': {outline: 'none',},
  '.cell-container': {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'visible',
    '> *': {maxWidth: '100%'}
  },
  '&.MuiDataGrid-cell--textCenter .cell-container': {justifyContent: 'center'},
  '&.MuiDataGrid-cell--textLeft .cell-container': {justifyContent: 'start'},
  '&.MuiDataGrid-cell--textRight .cell-container': {justifyContent: 'end'},
  '&.bold': {fontWeight: 500},
  '&.editable': {
    cursor: 'pointer',
    '.cell-container > *': {
      position: 'relative',
      '&:first-child::before': {
        content: '"edit"',
        fontFamily: 'Material Icons',
        color: theme.colors.text.white,
        background: theme.colors.text.highlight,
        fontSize: '.875rem',
        lineHeight: 1,
        position: 'absolute',
        right: theme.spacing(-1.375),
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
        padding: theme.spacing(.375),
        borderRadius: '50%',
        opacity: 0,
        pointerEvents: 'none',
        transition: theme.transitions.out.all,
        zIndex: 1,
      },
      '&::before': {bottom: 0,}, // We want this to be less specific
    },
    '&:hover .cell-container:not(.editing):not(:focus-within) > *:first-child::before': {
      opacity: 1,
      transition: theme.transitions.in.all,
    },
  },
}));

export function useCellContainerState() {
  const [editing, setEditing] = useState(false);

  const startEditing = useCallback(() => setEditing(true), []);

  const endEditing = useCallback(() => setEditing(false), []);

  return {
    editing,
    startEditing,
    endEditing,
  };
}

export const CellContainer = forwardRef(
function CellContainer({className, editing, onClick, children}, ref) {
  const handleClick = useCallback((e) => {
    e.stopPropagation();
    const editableCell = e.target.closest('.editable, .active');
    if (editableCell && onClick) {
      onClick(e);
    }
  }, [onClick]);

  // Stop DataGrid from putting cells in edit mode
  const handleStopPropagation = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <div
      ref={ref}
      className={classNames([className, 'cell-container', editing && 'editing'])}
      onClick={handleClick}
      onDoubleClick={handleStopPropagation}
      onMouseDown={handleStopPropagation}
      onMouseUp={handleStopPropagation}
      onKeyDown={handleStopPropagation}
    >
      {children}
    </div>
  );
});

CellContainer.propTypes = {
  className: HoopsPropTypes.className,
  editing: HoopsPropTypes.bool,
  onClick: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};

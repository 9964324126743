import React, {createContext, useCallback, useContext, useMemo} from 'react';
import {Row} from '../Layout';
import {registerGlobalStyle} from '../../theme';
import classNames from 'classnames';
import {HoopsPropTypes} from '../utils';

registerGlobalStyle('.tabs', (theme) => ({
  '&:is(ul)': {
    listStyle: 'none',
    minWidth: 'unset',
    borderBottom: `1px solid ${theme.colors.border.light}`,
    margin: 0,
    padding: theme.spacing(0, 1),
    backgroundColor: theme.colors.background.white,
  },
  '.tab': {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.125, 2, .875),
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    fontSize: '.875rem',
    color: theme.colors.text.main,
    transition: theme.transitions.out.all,
    cursor: 'pointer',
    textWrap: 'nowrap',
    '.text': {
      color: 'inherit',
      fontSize: 'inherit',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      inset: '0 0 0 0',
      borderBottom: '2px solid transparent',
      transition: theme.transitions.out.all,
    },
    '&.selected': {
      color: theme.colors.text.highlight,
      transition: theme.transitions.in.all,
      '&::after': {
        borderColor: theme.colors.border.highlight,
        borderBottom: `2px solid ${theme.colors.border.highlight}`,
        transition: theme.transitions.in.all,
      },
    },
    '&:hover': {
      backgroundColor: theme.colors.background.hover,
      transition: theme.transitions.in.all,
    },
    '& + .tab': {
      marginLeft: 1,
      '&::before': {
        content: '""',
        position: 'absolute',
        left: -1,
        top: 6,
        bottom: 8,
        borderLeft: `1px solid ${theme.colors.border.light}`,
      },
    },
    '&>svg': {
      height: theme.spacing(2.25),
      '&:first-child': {
        marginLeft: theme.spacing(-1),
        paddingRight: theme.spacing(.5),
        position: 'relative',
        top: -1,
      }
    },
    '.checkbox': {
      padding: theme.spacing(0, 1, 0, 0),
      svg: {fontSize: '150%',}
    }
  }
}));

const TabsContext = createContext(null);
const useTabsContext = () => useContext(TabsContext);

export function Tabs({className, tabs, value, onChange, children}) {
  const handleClick = useCallback((e) => {
    let newValue = e.target.getAttribute(value) ?? e.target.closest('li')?.getAttribute('value');
    if (tabs) {
      newValue = tabs.indexOf(newValue);
    }
    if (newValue != null && newValue !== value) {
      onChange?.(newValue);
    }
  }, [onChange, tabs, value]);

  const tabsState = useMemo(() => ({value: tabs ? tabs[value] : value,}), [tabs, value]);

  return (
    <TabsContext.Provider value={tabsState}>
      <Row className={[className, 'tabs']} tag={'ul'} onClick={handleClick}>
        {children &&
          children
        }
        {!children && tabs.map((tab, index) => (
          <Tab key={tab} value={tab} selected={index === value} text={tab}/>
        ))}
      </Row>
    </TabsContext.Provider>
  );
}

Tabs.propTypes = {
  className: HoopsPropTypes.className,
  tabs: HoopsPropTypes.arrayOfString,
  value: HoopsPropTypes.stringOrNumber,
  onChange: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};

export function Tab({className, value, text, children}) {
  const {value: tabsValue} = useTabsContext();

  return (
    <li className={classNames([className, 'tab', value === tabsValue && 'selected'])} value={value}>
      {text ?? children}
    </li>
  );
}

Tab.propTypes = {
  className: HoopsPropTypes.className,
  text: HoopsPropTypes.string,
  value: HoopsPropTypes.string,
  children: HoopsPropTypes.children,
};
